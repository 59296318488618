import React from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import HeaderLayout from '../layout/HeaderLayout';
import ScrollToTopButton from 'components/Common/ScrollToTopButton/ScrollToTopButton';
import useEnv from 'hooks/useEnv';
import Ariane from 'components/Ariane/Ariane';

function MainLayout({
  children,
  emptyLayout = false,
  recaptcha = false,
  arianeCurrentPath,
}: {
  children:
    | React.LazyExoticComponent<() => JSX.Element | null>
    | JSX.Element
    | null;
  emptyLayout?: boolean;
  recaptcha?: boolean;
  arianeCurrentPath?: string;
}): JSX.Element {
  const AfibelFooter = React.lazy(
    () => import('../components/AfibelFooter/AfibelFooter')
  );
  const { recaptchaId } = useEnv();

  function render(): JSX.Element {
    return (
      <>
        <div>
          {!emptyLayout && <HeaderLayout />}
          {arianeCurrentPath && <Ariane currentPath={arianeCurrentPath} />}
          <>{children}</>
          <ScrollToTopButton />
        </div>
        <AfibelFooter />
      </>
    );
  }

  return (
    <>
      {recaptcha ? (
        <GoogleReCaptchaProvider reCaptchaKey={recaptchaId}>
          {render()}
        </GoogleReCaptchaProvider>
      ) : (
        render()
      )}
    </>
  );
}

export default MainLayout;
