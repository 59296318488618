import React from 'react';
import classnames from 'classnames';

import styles from './Loader.module.scss';

/**
 * Loader displayed during a data load.
 */
function Loader({ className }: { className?: string }): JSX.Element {
  return <div className={classnames(styles.loader, className)}></div>;
}

export default Loader;
