import React, { useCallback, useRef } from 'react';

import FooterBannerCarousel from './FooterBannerCarousel/FooterBannerCarousel';
import MenuMobile from './MenuMobile/MenuMobile';
import DirectOrderLink from 'components/Header/RightNavigationMenu/DirectOrderLink/DirectOrderLink';
import useMenu from 'hooks/Menu/useMenu';

import styles from './NavigationMenuMobile.module.scss';

function NavigationMenuMobile({
  isVisible = false,
  classes,
}: {
  /** Show content */
  isVisible: boolean;
  classes?: {
    container?: string;
  };
}): JSX.Element | null {
  const { mobileMerchBanners: merchBanners, hierarchy } = useMenu();
  const containerRef = useRef<HTMLDivElement>(null);
  const onClickItems = useCallback(() => {
    containerRef.current?.scroll({ top: 0 });
  }, []);

  if (!isVisible) {
    return <></>;
  }

  return (
    <div ref={containerRef} className={classes?.container}>
      {hierarchy && <MenuMobile onClick={onClickItems} items={hierarchy} />}
      {merchBanners && <FooterBannerCarousel banners={merchBanners} />}
      <DirectOrderLink classes={{ navLink: styles.directOrderLink }} />
    </div>
  );
}

export default NavigationMenuMobile;
