import React, { ButtonHTMLAttributes } from 'react';
import classNames from 'classnames';

import PictoSearch from 'assets/img/headerV2/search.svg';

import styles from './SearchButton.module.scss';

type SearchButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  classes?: {
    button?: string;
    image?: string;
  };
};

function SearchButton({ classes, ...props }: SearchButtonProps): JSX.Element {
  return (
    <button
      className={classNames(styles.button, classes?.button)}
      type="button"
      {...props}
    >
      <img
        src={PictoSearch}
        alt="icon-search"
        height={28}
        width={27}
        className={classes?.image}
      />
    </button>
  );
}

export default SearchButton;
