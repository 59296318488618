import React, { ComponentPropsWithoutRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import { getUri } from 'services/generic';
import BREADCRUMB_PATHS from './arianeConfig';

import styles from './Ariane.module.scss';

export default function Ariane({
  currentPath,
}: {
  currentPath: string;
}): JSX.Element | null {
  const { t } = useTranslation();
  const currentBreadcrumb = BREADCRUMB_PATHS[currentPath];

  if (!currentBreadcrumb) return null;

  const { style, items } = currentBreadcrumb;
  const itemsLength = items.length;

  return (
    <nav aria-label="breadcrumb" className={styles.breadcrumb} style={style}>
      <ol className={styles['breadcrumb-items']}>
        <BreadcrumbItem>
          <BreadcrumbLink href={getUri('/')}>{t('LABEL_HOME')}</BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbSeparator />
        {items.map((item, i) => {
          const isLastItem = i === itemsLength - 1;

          return (
            <React.Fragment key={i}>
              <BreadcrumbItem>
                {!isLastItem && (
                  <BreadcrumbLink href={item.url || '#'}>
                    {item.label}
                  </BreadcrumbLink>
                )}
                {isLastItem && <BreadcrumbPage>{item.label}</BreadcrumbPage>}
              </BreadcrumbItem>
              {!isLastItem && <BreadcrumbSeparator />}
            </React.Fragment>
          );
        })}
      </ol>
    </nav>
  );
}

function BreadcrumbLink({
  className,
  href,
  ...props
}: ComponentPropsWithoutRef<'a'>) {
  return (
    <Link
      className={classNames(styles.link, className)}
      to={href as string}
      {...props}
    />
  );
}

function BreadcrumbItem({
  className,
  ...props
}: ComponentPropsWithoutRef<'li'>): JSX.Element {
  return (
    <li
      className={classNames(styles['breadcrumb-item'], className)}
      {...props}
    />
  );
}

function BreadcrumbPage({
  className,
  ...props
}: ComponentPropsWithoutRef<'span'>) {
  return (
    <span
      className={classNames(styles.link, styles.current, className)}
      role="link"
      aria-disabled="true"
      aria-current="page"
      {...props}
    />
  );
}

function BreadcrumbSeparator(): JSX.Element {
  return (
    <BreadcrumbItem
      role="presentation"
      aria-hidden="true"
      className={styles.separator}
    >
      <span>|</span>
    </BreadcrumbItem>
  );
}
