import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import Card from 'components/Account/Common/Card/Card';
import Image from 'components/Common/Medias/Image';
import { ENV_TWICPICS_URL_MEDIA } from 'settings/env';
import { Navlink } from 'settings/navLinkAccountMenu';

import ArrowActive from 'assets/img/Account/arrowActive.png';
import ArrowNotActive from 'assets/img/Account/arrowNotActive.png';

import styles from './MyAccountMenuDesktop.module.scss';

function MyAccountMenuDesktop({
  classes,
}: {
  classes?: {
    container?: string;
  };
}): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <Card
      classes={{
        container: classNames(styles.container, classes?.container),
      }}
    >
      <div className={styles.title}>
        <strong>{t('LABEL_MYACCOUNT_TITLE')}</strong>
      </div>
      <div>
        <ul>
          {Navlink.map(nav => {
            const { to, classname, logout, label, lng } = nav;
            const urlActive =
              window.location.href.substr(
                window.location.href.indexOf('/account/') + 9,
                5
              ) == to.substr(to.indexOf('/account/') + 9, 5);

            return (
              (!lng || lng === 'uk') && (
                <li key={to}>
                  <NavLink
                    className={
                      classname
                        ? styles.logout
                        : urlActive
                        ? styles.isActive
                        : ''
                    }
                    to={to}
                    onClick={() => {
                      navigate(to);
                    }}
                  >
                    {urlActive ? (
                      <img src={ArrowActive} />
                    ) : !logout && !urlActive ? (
                      <img src={ArrowNotActive} />
                    ) : (
                      logout && (
                        <Image
                          src={`${ENV_TWICPICS_URL_MEDIA}/wysiwyg/mon-compte/images/closeIcone.png`}
                          className={styles.twicImageCross}
                          ratio={''}
                          preTransform={''}
                        />
                      )
                    )}
                    <label>{label}</label>
                  </NavLink>
                </li>
              )
            );
          })}
        </ul>
      </div>
    </Card>
  );
}

export default MyAccountMenuDesktop;
