import React from 'react';
import { useTranslation } from 'react-i18next';

import CustomLink from 'components/Common/CustomLink/CustomLink';
import useCurrentCountry from 'hooks/useCurrentCountry';
import { getUri } from 'services/generic';

import HelpPicto from 'assets/img/headerV2/help.svg';

type HelpLinkProps = {
  classes?: {
    navLink?: string;
    image?: string;
  };
};

function HelpLink({ classes }: HelpLinkProps): JSX.Element {
  const { t } = useTranslation();
  const { isFr } = useCurrentCountry();

  return (
    <CustomLink
      className={classes?.navLink}
      to={getUri(isFr ? '/aide' : '/help')}
      title={t('LABEL_TAB_HELP')}
    >
      <img
        src={HelpPicto}
        alt={t('LABEL_TAB_HELP')}
        className={classes?.image}
        width={25}
        height={28}
      />
      <span>{t('LABEL_TAB_HELP')}</span>
    </CustomLink>
  );
}

export default HelpLink;
