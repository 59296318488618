import React, {
  MouseEvent,
  MutableRefObject,
  TouchEvent,
  useRef,
  useState,
} from 'react';
import classNames from 'classnames';
import { ClickAwayListener } from '@material-ui/core';

import MegaMenu from './MegaMenu/MegaMenu';
import CustomLink from 'components/Common/CustomLink/CustomLink';
import { getUri } from 'services/generic';
import { navigationFormatId } from 'services/string';
import {
  MenuBannerType,
  MenuItemType,
  ServiceBannerType,
} from 'types/menu/Menu';

import styles from './MenuItem.module.scss';

export type MenuItemProps = {
  item: MenuItemType;
  banners: MenuBannerType[] | undefined;
  serviceBanner: ServiceBannerType | undefined;
  delayOpening: (callback: () => void) => void;
  classes?: {
    container?: string;
  };
};

function MenuItem({
  item,
  banners,
  serviceBanner,
  delayOpening,
  classes,
}: MenuItemProps): JSX.Element {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const isOpenDisabled = useRef<boolean>(false);
  const isTouchAction = useRef<boolean>(false);
  const anchorElRef = useRef<HTMLLIElement>(null);
  const [open, setOpen] = useState<boolean>(false);
  const { label, url, children, id: itemId, metadata } = item;
  const { menuNoClick, cssClass } = metadata;
  const megaMenuIdentifier = `megaMenuContainer-${itemId}`;
  const id = `oct_megamenu_${navigationFormatId(label)}`;
  const gtmMenuClickEventData = {
    event: '_menu_click',
    menu_level_1: label.toLowerCase(),
  };

  function openMegaMenu() {
    !isOpenDisabled.current &&
      delayOpening(() => {
        setOpen(true);
      });
  }

  function closeMegaMenu() {
    setOpen(false);
  }

  function touchHandler(e: TouchEvent<HTMLLIElement>) {
    const target = e.target as HTMLElement;
    if (!target.closest(`#${megaMenuIdentifier}`)) {
      setOpen(open => !open);
    }
  }

  function handleClick(
    ev: MouseEvent<HTMLAnchorElement> | TouchEvent<HTMLAnchorElement>
  ) {
    if (ev.type === 'touchstart') {
      isTouchAction.current = true;
    }

    if (isTouchAction.current && children?.length) {
      ev.preventDefault();
    }

    if (ev.type === 'click') {
      if (!isTouchAction.current) {
        setOpen(false);

        isOpenDisabled.current = true;
        timeoutRef.current && clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(() => {
          isOpenDisabled.current = false;
        }, 200);
      }

      isTouchAction.current = false;
    }
  }

  return (
    <ClickAwayListener onClickAway={closeMegaMenu}>
      <li
        className={classNames(styles.container, classes?.container, {
          [styles.open]: open,
        })}
        onTouchStart={touchHandler}
        onMouseEnter={openMegaMenu}
        onMouseLeave={closeMegaMenu}
        ref={anchorElRef}
      >
        <CustomLink
          id={id}
          to={menuNoClick ? '#' : getUri(url)}
          className={classNames(
            { [styles.disabled]: menuNoClick },
            cssClass || undefined
          )}
          onClick={handleClick}
          onTouchStart={handleClick}
        >
          <span>{label}</span>
        </CustomLink>
        {children && (
          <>
            <MegaMenu
              idContainer={megaMenuIdentifier}
              items={children}
              banners={banners}
              serviceBanner={serviceBanner}
              open={open}
              closeMegaMenu={closeMegaMenu}
              gtmMenuClickEventData={gtmMenuClickEventData}
              anchorRef={anchorElRef as MutableRefObject<HTMLElement>}
              prefixId={id}
            />
            <div
              className={classNames(styles.overlay, {
                [styles.show]: open,
              })}
            />
          </>
        )}
      </li>
    </ClickAwayListener>
  );
}

export default MenuItem;
