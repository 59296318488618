import React from 'react';
import { TwicImg } from '@twicpics/components/react';

type Props = React.PropsWithChildren<{
  src: string;
  alt?: string;
  className?: string;
  ratio?: string;
  preTransform?: string;
}>;

function Image({
  src,
  alt,
  className = '',
  ratio = '3/4',
  preTransform = 'quality=85',
  children,
}: Props): JSX.Element {
  return (
    <TwicImg
      className={className}
      src={src}
      alt={alt || src}
      ratio={ratio}
      preTransform={preTransform}
    >
      {children}
    </TwicImg>
  );
}

export default Image;
