import { t } from 'i18next';

import { getRoutesUri } from 'services/generic';
import useCurrentCountry from 'hooks/useCurrentCountry';

const { country } = useCurrentCountry();

export const Navlink = [
  {
    to: getRoutesUri('customerAccountOrderHistory') as string,
    label: t('LABEL_MYACCOUNT_ORDER'),
    titleActiveMenu: 'history',
  },
  {
    to: getRoutesUri('customerAccountInformation') as string,
    label: t('LABEL_PERSONALINFORMATION_TITLE'),
    titleActiveMenu: 'informations',
  },
  {
    to: getRoutesUri('customerAccountPersonalAccount') as string,
    label: t('LABEL_MYACCOUNT_PERSONALPAYONLINE'),
    lng: country,
    titleActiveMenu: 'personalAccount',
  },
  {
    to: getRoutesUri('customerAccountNewsletter') as string,
    label: t('LABEL_SUMMARY_SUBSCRIPTION_EDIT'),
    titleActiveMenu: 'newsletter',
  },
  {
    to: getRoutesUri('customerAccountCatalogRequest') as string,
    label: t('LABEL_MYACCOUNT_CATALOG'),
    titleActiveMenu: 'catalogRequest',
  },
  {
    classname: true,
    to: getRoutesUri('customerAccountLogOutSuccess') as string,
    logout: true,
    label: t('LABEL_MYACCOUNT_SIGNOUT'),
  },
];

export const isActiveStyleMobile = {
  fontWeight: 'bold',
  color: '#666061',
};
