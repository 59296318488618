import React from 'react';
import classNames from 'classnames';

import Image from 'components/Common/Medias/Image';
import { SPECIFIC_BRANDS } from 'services/product';
import { ENV_TWICPICS_URL_MEDIA } from 'settings/env';
import { CATEGORY_IMAGE_PRODUCT_HEIGHT } from 'settings/imageSizes';
import { CarouselProductType } from 'types/Product';

import styles from './ProductCarousel.module.scss';

export type Props = {
  classes?: CarouselProductClassesType;
  product: CarouselProductType;
};

type CarouselProductClassesType = {
  shortLabel?: string;
  thumbnail?: string;
};

export default function ProductCarousel({
  classes,
  product,
}: Props): JSX.Element {
  const {
    commercialOperationSticker,
    metaMarque,
    shortLabel,
    visuelFace,
  } = product;
  const specificBrand = SPECIFIC_BRANDS.includes(metaMarque || '');
  const opeco = commercialOperationSticker?.split('¤');

  function renderTopSticker(): JSX.Element {
    if (!opeco || opeco.length < 2) {
      return <></>;
    }

    return (
      <div className={styles.topSticker} id="topSticker">
        <span
          className={classNames(styles.sticker, 'noselect')}
          style={{
            background: opeco[1] || '',
          }}
        >
          {opeco[0]}
        </span>
      </div>
    );
  }

  return (
    <div className={styles.productContainer}>
      <div>
        {renderTopSticker()}
        <Image
          alt={shortLabel}
          className={classNames(styles.productThumbnail, classes?.thumbnail)}
          ratio={`400/${CATEGORY_IMAGE_PRODUCT_HEIGHT.toString()}`}
          src={`${ENV_TWICPICS_URL_MEDIA}${visuelFace}`}
        />
      </div>
      <div className={classNames(styles.shortLabel, classes?.shortLabel)}>
        {specificBrand && <span>{metaMarque}&#174; - </span>}
        <span className={classNames(styles.shortLabel, classes?.shortLabel)}>
          {shortLabel}
        </span>
      </div>
    </div>
  );
}
