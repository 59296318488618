import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

import MyAccountMenuDesktop from 'components/Account/MyAccountMenu/MyAccountMenuDesktop/MyAccountMenuDesktop';
import MyAccountMenuMobile from 'components/Account/MyAccountMenu/MyAccountMenuMobile/MyAccountMenuMobile';
import { MOBILE_AND_TABLET_PORTRAIT } from 'settings/mediaQuery';

import styles from './MyAccountMenu.module.scss';

type MenuProps = {
  classes?: { menu?: { container?: string } };
};

function MyAccountMenu({ classes }: MenuProps): JSX.Element {
  const BODY_CLASS_NAME = styles.customerNavigationOpen;
  const { t } = useTranslation();
  const isMobileAndPortraitTablet = useMediaQuery({
    query: MOBILE_AND_TABLET_PORTRAIT,
  });
  const [openMenu, setOpenMenu] = useState<boolean>(false);
  const handleActionMenu = (value: boolean) => {
    setOpenMenu(value);
  };

  document.body.classList[openMenu ? 'add' : 'remove'](BODY_CLASS_NAME);

  useEffect(() => {
    !isMobileAndPortraitTablet && setOpenMenu(false);
  }, [isMobileAndPortraitTablet]);

  useEffect(() => {
    return () => {
      document.body.classList.remove(BODY_CLASS_NAME);
    };
  }, []);

  return (
    <>
      {openMenu ? (
        <MyAccountMenuMobile
          actionMenu={handleActionMenu}
          classes={classes?.menu}
        />
      ) : isMobileAndPortraitTablet && !openMenu ? (
        <div className={styles.redirectionMenu}>
          <a onClick={() => setOpenMenu(true)}>
            <FontAwesomeIcon className={styles.action} icon={faChevronLeft} />
            <label>{t('LABEL_BACK_TO_MENU')}</label>
          </a>
        </div>
      ) : (
        <MyAccountMenuDesktop classes={classes?.menu} />
      )}
    </>
  );
}

export default MyAccountMenu;
