import { Action } from '@remix-run/router';

import { historyContext } from 'contexts/historyContext';
import React, { useEffect, useState } from 'react';
import {
  NavigationType,
  useLocation,
  useNavigationType,
} from 'react-router-dom';
import { HistoryStateType } from 'types/History';

export default function HistoryProvider(props: {
  children: JSX.Element;
}): JSX.Element {
  const { pathname } = useLocation();
  const navigationType: NavigationType = useNavigationType();
  const [history, setHistory] = useState<HistoryStateType[]>([]);
  const isLoaded =
    !!history.length &&
    history[history.length - 1].location === window.location.href;

  useEffect(() => {
    const location = window.location.href;
    const historyCopy = [...history];

    switch (navigationType) {
      case Action.Push:
        setHistory([...historyCopy, { location }]);
        break;
      case Action.Pop:
        setHistory(
          historyCopy.length ? historyCopy.slice(0, -1) : [{ location }]
        );
        break;
      case Action.Replace:
        setHistory([...historyCopy.slice(0, -1), { location }]);
        break;
    }
  }, [pathname]);

  return (
    <historyContext.Provider
      value={{
        history,
        isLoaded,
      }}
    >
      {props.children}
    </historyContext.Provider>
  );
}
