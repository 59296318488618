import React from 'react';
import { useNavigate } from 'react-router-dom';

import { useCustomer } from 'hooks/useCustomer';
import { getRoutesUri } from 'services/generic';

const CustomerRoute = ({
  children,
  accessNotLogged,
  redirectPath,
  redirectOverseas,
}: {
  children: JSX.Element;
  accessNotLogged?: boolean;
  redirectPath?: string;
  redirectOverseas?: boolean;
}): JSX.Element => {
  const navigate = useNavigate();
  const { isLogged, customer } = useCustomer();

  if (accessNotLogged && isLogged) {
    return (
      <>
        {navigate(
          redirectPath ||
            (getRoutesUri('customerAccountOrderHistory') as string)
        )}
      </>
    );
  }

  if (!accessNotLogged && !isLogged) {
    return <>{navigate(getRoutesUri('customerLogin') as string)}</>;
  }

  if (isLogged && redirectOverseas && customer?.overseas === '1') {
    return (
      <>{navigate(getRoutesUri('customerAccountInformation') as string)}</>
    );
  }

  return children;
};

export default CustomerRoute;
