import React, { useMemo } from 'react';
import classnames from 'classnames';
import { useMediaQuery } from 'react-responsive';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { Options } from '@splidejs/splide';
import Image from 'components/Common/Medias/Image';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

import CustomLink from 'components/Common/CustomLink/CustomLink';
import Price from 'components/Common/Price/Price';
import { getDataFromAttributes } from 'services/data';
import { getProductData } from 'services/gtm/gtmPageView';
import { formatProduct } from 'services/gtm/gtmUtils';
import { pushGtmEvent } from 'services/gtm/gtmEvents';
import { getUri } from 'services/generic';
import { SPECIFIC_BRANDS } from 'services/product';
import { ENV_TWICPICS_URL_MEDIA } from 'settings/env';
import { MOBILE_AND_TABLET } from 'settings/mediaQuery';
import { CATEGORY_IMAGE_PRODUCT_HEIGHT } from 'settings/imageSizes';
import { ProductDetail } from 'types/Controls/ProductSheet';

import styles from './CarouselProducts.module.scss';

/**
 * Display a list of products in carousel
 */
export function CarouselProducts(props: {
  /** List of products */
  products?: CarouselProductType[];
  /** List of products details */
  productsDetails?: ProductDetail[];
  /** Products attributes return by Octipas API */
  attributes?: { [key: string]: { Label: string; MainName: string } };
  /** Function onClose in search mobile */
  closeAcp?: () => void;
  /** Number of products display */
  productPerPage: number;
  /** Display discount percentage */
  displayDiscount?: boolean;
  /** Overloading css priceTotalLook class */
  priceTotalLookClassName?: string;
  /** Overloading css detailTotalLook class */
  detailTotalLookClassName?: string;
  /** Overloading css carouselProducts class */
  carouselProductsClassName?: string;
  /** Overloading css prevArrow class */
  prevArrowClassname?: string;
  /** Overloading css nextArrow class */
  nextArrowClassname?: string;
  /** Overloading css wrapper class */
  wrapperClassName?: string;
  /** Function when product is clicked */
  onClickProduct?: () => void;
  /** Overloading css productThumbnail class */
  productThumbnailClassName?: string;
  /** Overloading css carouselShortLabel class */
  carouselShortLabelClassName?: string;
  /** Discount percentage */
  discountPercentage?: string;
  /** gtm element label */
  gtmElementLabel?: string;
  /** Override / Additional options to splide component */
  splideOptions?: Options;
}): JSX.Element | null {
  const {
    products,
    productsDetails,
    attributes = {},
    closeAcp,
    productPerPage,
    displayDiscount = true,
    priceTotalLookClassName,
    detailTotalLookClassName,
    carouselProductsClassName,
    prevArrowClassname,
    nextArrowClassname,
    wrapperClassName,
    onClickProduct,
    productThumbnailClassName,
    carouselShortLabelClassName,
    gtmElementLabel,
    splideOptions,
  } = props;
  const isMobileAndTablet = useMediaQuery({
    query: MOBILE_AND_TABLET,
  });

  const options = useMemo(() => {
    return {
      ...{
        margin: 0,
        rewind: false,
        perPage: productPerPage,
        perMove: 1,
        gap: isMobileAndTablet ? undefined : '16px',
        pagination: false,
        arrowPath:
          'M 29.385104,18.742152 14.438914,3.8963932 11.391965,6.0817785 24.548511,19.146665 l -12.486154,13.707 3.152136,2.015241 14.184421,-15.575218 0.322642,-0.231816 -0.05858,-0.05819 0.0556,-0.06105 z',
        classes: {
          prev: `splide__arrow--prev ${prevArrowClassname} ${
            products && productPerPage >= products.length ? 'hidden' : ''
          }`,
          next: `splide__arrow--next ${nextArrowClassname} ${
            products && productPerPage >= products.length ? 'hidden' : ''
          }`,
        },
      },
      ...(splideOptions || {}),
    };
  }, [
    splideOptions,
    isMobileAndTablet,
    productPerPage,
    products,
    prevArrowClassname,
    nextArrowClassname,
  ]);

  if (!products?.length) {
    return null;
  }

  const renderProduct = (product: CarouselProductType) => {
    const {
      shortLabel,
      visuelFace,
      price,
      discountPrice,
      uniquePrice,
      discountPercentage,
      commercialOperationSticker,
      metaMarque,
      metaLot,
    } = product;

    const specificBrand = SPECIFIC_BRANDS.includes(metaMarque || '');
    const opeco = commercialOperationSticker?.split('¤');

    function topSticker(opeco: string[]) {
      return (
        <div className={styles.topSticker} id="topSticker">
          <span
            className={classnames(styles.sticker, 'noselect')}
            style={{
              background: opeco[1] || '',
            }}
          >
            {opeco[0]}
          </span>
        </div>
      );
    }

    return (
      <div className={styles.productContainer}>
        <div className={productThumbnailClassName}>
          {opeco && opeco.length > 1 && topSticker(opeco)}
          <Image
            alt={shortLabel}
            ratio={`400/${CATEGORY_IMAGE_PRODUCT_HEIGHT.toString()}`}
            src={ENV_TWICPICS_URL_MEDIA + visuelFace}
          />
        </div>
        <div className={classnames(styles.details, detailTotalLookClassName)}>
          <div
            className={classnames(
              styles.shortLabel,
              carouselShortLabelClassName
            )}
          >
            {specificBrand && <span>{metaMarque}&#174; - </span>}

            {shortLabel}
          </div>
          <Price
            priceContainerClassName={classnames(
              styles.searchPriceContainer,
              priceTotalLookClassName
            )}
            price={price}
            priceIfDiscount={discountPrice}
            uniquePrice={uniquePrice}
            discountPercentage={discountPercentage}
            productPriceClassName={styles.productPrice}
            newPriceContainerClassName={styles.newPriceContainer}
            newPriceClassName={styles.newPrice}
            priceIfDiscountClassName={styles.priceIfDiscount}
            discountPercentageClassName={
              !displayDiscount ? styles.hideDiscount : undefined
            }
            lot={metaLot}
          />
        </div>
      </div>
    );
  };

  return (
    <div className={classnames('wrapper', wrapperClassName)}>
      <Splide
        className={classnames('carouselProducts', carouselProductsClassName)}
        options={options}
      >
        {products?.map((product, index) => {
          const { productId, url } = product;

          return (
            <SplideSlide key={productId}>
              <CustomLink
                to={getUri(url)}
                id={`product-carousel-${productId}`}
                className={styles.productLink}
                href={getUri(url)}
                key={productId}
                onClick={() => {
                  scrollTo(0, 0);
                  localStorage.setItem('previous-url', '');
                  if (productsDetails) {
                    const productDataListing = getDataFromAttributes(
                      productsDetails[index].Attributes,
                      attributes
                    );

                    pushGtmEvent({
                      event: '_product_click',
                      category: 'recommandation de produit',
                      element: gtmElementLabel,
                      products: [
                        {
                          ...getProductData(formatProduct(productDataListing)),
                          list_position: index + 1,
                        },
                      ],
                    });
                  }
                  if (typeof closeAcp === 'function') {
                    closeAcp();
                  }
                  if (typeof onClickProduct === 'function') {
                    onClickProduct();
                  }
                }}
              >
                {renderProduct(product)}
              </CustomLink>
            </SplideSlide>
          );
        })}
      </Splide>
    </div>
  );
}

export type CarouselProductType = {
  productId: string | number;
  visuelFace: string;
  price: string;
  discountPrice: string;
  uniquePrice: string;
  shortLabel: string;
  url: string;
  priceTotalLookClassName?: string;
  detailTotalLookClassName?: string;
  skuId?: string;
  color?: string;
  size?: string;
  stock?: string;
  metaMarque?: string;
  metaLot: string;
  modelName?: string;
  family?: string;
  subfamily?: string;
  subuniverse?: string;
  universe?: string;
  discountPercentage?: string;
  commercialOperationSticker?: string;
  carouselShortLabelClassName?: string;
};
