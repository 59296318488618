import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import UserDropdownMenu from './UserDropdownMenu/UserDropdownMenu';
import CustomLink from 'components/Common/CustomLink/CustomLink';
import { useCustomer } from 'hooks/useCustomer';
import { getRoutesUri } from 'services/generic';
import { pushGtmEvent } from 'services/gtm/gtmEvents';

import AccountPicto from 'assets/img/headerV2/account.svg';
import MyAccountPicto from 'assets/img/headerV2/connected-account.svg';

import styles from './MyAccountLink.module.scss';

export type MyAccountLinkProps = {
  classes?: {
    container?: string;
    containerLogged?: string;
    navLink?: string;
    image?: string;
  };
  allowLoggedDropdown?: boolean;
};

function MyAccountLink({
  classes,
  allowLoggedDropdown = true,
}: MyAccountLinkProps): JSX.Element {
  const { t } = useTranslation();
  const { customer, isLogged } = useCustomer();
  const customerUrl = isLogged
    ? 'customerAccountOrderHistory'
    : 'customerLogin';

  const handleClick = () => {
    pushGtmEvent({
      event: '_header_click',
      category: 'header',
      element: 'mon compte',
    });
  };

  return (
    <div
      className={classNames(styles.container, classes?.container, {
        [classNames(styles.isLogged, classes?.containerLogged)]: isLogged,
      })}
    >
      <CustomLink
        className={classNames(classes?.navLink, styles.navLink)}
        to={getRoutesUri(customerUrl) as string}
        title={t('LABEL_MYACCOUNT_TITLE')}
        onClick={handleClick}
      >
        <img
          src={isLogged ? MyAccountPicto : AccountPicto}
          alt="my-account"
          className={classes?.image}
          width={isLogged ? 36 : 27}
          height={28}
        />
        <span>{t('LABEL_MYACCOUNT_TITLE')}</span>
      </CustomLink>
      {isLogged && allowLoggedDropdown && (
        <UserDropdownMenu
          customer={customer}
          classes={{ container: styles.dropdown }}
        />
      )}
    </div>
  );
}

export default MyAccountLink;
