import React from 'react';

import { replaceByLinkEnv } from 'services/formatter';

/**
 * Banner display in ProductPage.
 */
function InsertHtmlContent({
  htmlContent,
  className,
}: {
  htmlContent: string;
  className?: string;
}): JSX.Element {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: replaceByLinkEnv(htmlContent) }}
      className={className}
    ></div>
  );
}

export default InsertHtmlContent;
