import React from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';

import FooterBannerCarouselExtension from './FooterBannerCarouselExtension';
import { replaceByLinkEnv } from 'services/formatter';
import { MobFooterBanner } from 'types/menu/Menu';

import styles from './FooterBannerCarousel.module.scss';

type FooterBannerCarousel = {
  banners: MobFooterBanner[];
};

const FooterBannerCarousel = ({
  banners,
}: FooterBannerCarousel): JSX.Element => {
  return (
    <div className={styles.merchCarousel}>
      <Splide
        extensions={{
          footerBannerCarousel: FooterBannerCarouselExtension,
        }}
        options={{
          rewind: true,
          type: 'loop',
          gap: '0px',
          arrows: false,
          autoplay: true,
          interval: 3000,
          classes: {
            pagination: `splide__pagination ${styles.carouselPaginationDots}`,
          },
        }}
      >
        {banners.map((banner, i) => (
          <SplideSlide key={i}>
            <div
              className={styles.carouselItem}
              dangerouslySetInnerHTML={{
                __html: replaceByLinkEnv(banner.htmlContent),
              }}
            />
          </SplideSlide>
        ))}
      </Splide>
    </div>
  );
};

export default FooterBannerCarousel;
