import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { CustomeraddressType, CustomerType } from 'types/Customer';
import useSWR from 'swr';
import { fetcher, fetcherWithBody } from 'services/fetcher';
import useEnv from './useEnv';

export type LoginValues =
  | { email: string; password: string; surname?: string; nClient?: string }
  | { email?: string; password?: string; surname: string; nClient: string };

type LoginResponse = {
  message: string;
  statusCode: number;
  customer: CustomerType;
  errors?: Record<string, string>;
};

export function useCustomer(): {
  customer?: CustomerType;
  primaryaddress: CustomeraddressType | null;
  secondaryaddress: CustomeraddressType | null;
  smsOptin: string | boolean | null | undefined;
  isLogged?: boolean | null;
  login: (values: LoginValues) => Promise<LoginResponse>;
  isValidating: boolean;
  isLoaded: boolean;
  logout: () => Promise<void>;
  refreshCustomer: () => Promise<CustomerType | undefined>;
} {
  const { urlApiCustomer } = useEnv();
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { data: customer, isValidating, mutate, error } = useSWR<CustomerType>(
    `${urlApiCustomer}/get`,
    fetcher
  );

  const refreshCustomer = () => {
    return mutate();
  };

  return {
    customer,
    get primaryaddress() {
      return customer?.addresses ? customer.addresses['P'] : null;
    },
    get secondaryaddress() {
      return customer?.addresses ? customer.addresses['S'] : null;
    },
    get smsOptin() {
      if (customer?.cellPhone === '') {
        return null;
      } else {
        return customer?.smsOption;
      }
    },
    isLogged: customer?.isLoggedIn !== undefined ? customer.isLoggedIn : null,
    login: async (values): Promise<LoginResponse> => {
      const { email, password, surname, nClient } = values;

      if (!executeRecaptcha) {
        return Promise.reject({ type: 'reCAPTCHANotLoaded' });
      }

      const gReCaptchaToken = await executeRecaptcha('login');

      let body = {};
      if (email && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)) {
        body = { email, password };
      } else {
        body = { nClient: email, password };
      }

      if (surname && nClient) {
        body = { surname, nClient };
      }

      const data = {
        ...body,
        tokenRecaptcha: gReCaptchaToken,
      };

      return fetcherWithBody(`${urlApiCustomer}/login`, data, true)
        .then(response => response.json())
        .then(responseData => {
          if (responseData.customer) {
            mutate(responseData.customer);
          }

          return responseData;
        });
    },
    isValidating,
    isLoaded: customer || error,
    logout: async () => {
      return fetcher(`${urlApiCustomer}/logout`).then(response => {
        mutate(response.customer || []);
        return response;
      });
    },
    refreshCustomer,
  };
}
