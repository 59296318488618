import { useCustomSWR } from 'hooks/useCustomSWR';
import { useDebounce } from 'hooks/useDebounce';
import { apiUrls } from 'services/api-urls';
import { fetcher } from 'services/fetcher';
import { Acp } from 'types/Acp';

const DEBOUNCE_DELAY_MS = 500;

function useAcp(
  searchKeyword: string
): {
  acpData: Acp | undefined;
} {
  const debouncedSearch = useDebounce(searchKeyword, DEBOUNCE_DELAY_MS);
  const triggerSearch = debouncedSearch.length > 1;

  const { data: acpData } = useCustomSWR<Acp>(
    triggerSearch ? apiUrls.getAcp(debouncedSearch) : null,
    fetcher,
    { revalidateOnFocus: false }
  );

  return { acpData };
}

export default useAcp;
