import React, { MutableRefObject, useEffect, useRef } from 'react';
import classnames from 'classnames';

import MegaMenuItems from './MegaMenuItems/MegaMenuItems';
import InsertHtmlContent from 'components/Common/InsertHtmlContent/InsertHtmlContent';
import {
  groupItemsByColumns,
  hasAtLeastOneItemsVisible,
} from 'services/menu/menu';
import { GtmMenuClick } from 'types/gtm/GtmEvent';
import {
  MenuBannerType,
  MenuItemType,
  ServiceBannerType,
} from 'types/menu/Menu';

import styles from './MegaMenu.module.scss';

const MEGA_MENU_ITEMS_DEPTH = 0;

function MegaMenu({
  idContainer,
  items,
  banners,
  serviceBanner,
  open,
  closeMegaMenu,
  gtmMenuClickEventData,
  anchorRef,
  prefixId,
}: {
  idContainer: string;
  items: MenuItemType[];
  banners: MenuBannerType[] | undefined;
  serviceBanner: ServiceBannerType | undefined;
  open: boolean;
  closeMegaMenu: () => void;
  gtmMenuClickEventData: GtmMenuClick;
  anchorRef: MutableRefObject<HTMLElement>;
  prefixId?: string;
}): JSX.Element {
  const containerRef = useRef<HTMLDivElement>(null);
  const itemsByColumns = groupItemsByColumns(items, column => column > 2);
  const bannersColumnStart = 3;

  function setMaxHeightToBottom(element: HTMLElement, anchor: HTMLElement) {
    const elPosition = anchor.getBoundingClientRect()['bottom'];
    const maxHeight = window.innerHeight;

    element.style.setProperty(
      '--maxHeightToBottom',
      `${maxHeight - elPosition}px`
    );
  }

  if (open && anchorRef.current && containerRef.current) {
    // Fix max height when the navigation rebuilds the header (ex: other layout)
    setMaxHeightToBottom(containerRef.current, anchorRef.current);
  }

  useEffect(() => {
    function setMaxHeightToBottomScroll() {
      if (anchorRef.current && containerRef.current) {
        setMaxHeightToBottom(containerRef.current, anchorRef.current);
      }
    }

    setMaxHeightToBottomScroll();
    window.addEventListener('scroll', setMaxHeightToBottomScroll);
    window.addEventListener('resize', setMaxHeightToBottomScroll);
    return () => {
      window.removeEventListener('scroll', setMaxHeightToBottomScroll);
      window.removeEventListener('resize', setMaxHeightToBottomScroll);
    };
  }, [containerRef, anchorRef]);

  return (
    <div
      id={idContainer}
      ref={containerRef}
      className={classnames(styles.container, {
        [styles.open]: open,
      })}
    >
      <div className={styles.content}>
        {Object.keys(itemsByColumns).map(column => {
          const itemsByColumn: MenuItemType[] = itemsByColumns[column];
          if (!hasAtLeastOneItemsVisible(itemsByColumn, 'menuNoLayer')) {
            return false;
          }

          return (
            <div
              key={column}
              className={`${styles.column} ${styles[`column-${column}`]}`}
            >
              <MegaMenuItems
                items={itemsByColumns[column]}
                depth={MEGA_MENU_ITEMS_DEPTH}
                onClick={closeMegaMenu}
                gtmMenuClickEventData={gtmMenuClickEventData}
                prefixId={prefixId}
              />
            </div>
          );
        })}

        {banners?.map((banner, index) => {
          const key = bannersColumnStart + index;

          return (
            <InsertHtmlContent
              key={key}
              htmlContent={banner.htmlContent}
              className={`${styles.banner} ${styles[`column-${key}`]}`}
            />
          );
        })}
      </div>
      {serviceBanner && (
        <InsertHtmlContent
          className={styles.serviceBanner}
          htmlContent={serviceBanner.htmlContent}
        />
      )}
    </div>
  );
}

export default MegaMenu;
