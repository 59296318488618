const useEnv = (): {
  env: string;
  isIsicomEnable: boolean;
  country: string;
  priceCurrency: string;
  urlApiCatalog: string;
  urlApiCheckout: string;
  urlApiCms: string;
  urlApiColissimo: string;
  urlApiCustomer: string;
  urlApiMenu: string;
  urlApiProduct: string;
  urlApiRecapcha: string;
  urlApiSearch: string;
  urlCustomer: string;
  gtmId: string;
  gtmAuth: string;
  gtmPreview: string;
  gtmMediaId: string;
  gtmMediaAuth: string;
  gtmMediaPreview: string;
  recaptchaId: string;
  mediaFooter: string;
  mediaPopin: string;
  mediaOctipasCms: string;
  mediaProductSheet: string;
  mediaMyAccount: string;
  mediaOupsBanner: string;
  mediaTwicpic: string;
  dockerDevEnv: string;
  datetimeApp: string;
  baseUrlTwicpicsMedia: string;
  urlTwicpicsMedia: string;
  urlWysiwygOctipas: string;
  urlTwicpicsVideo: string;
} => ({
  env: process.env.REACT_APP_ENV as string,
  isIsicomEnable: process.env.REACT_APP_ENABLE_ISICOM === '1',
  country: process.env.REACT_APP_COUNTRY as string,
  priceCurrency: process.env.REACT_APP_CURRENCY_FUNDS_CODE as string,
  urlCustomer: process.env.REACT_APP_URL_CUSTOMER as string,

  // API URL
  urlApiCatalog: process.env.REACT_APP_URL_API_CATALOG as string,
  urlApiCheckout: process.env.REACT_APP_URL_API_CHECKOUT as string,
  urlApiCms: process.env.REACT_APP_URL_API_CMS as string,
  urlApiColissimo: process.env.REACT_APP_URL_API_COLISSIMO as string,
  urlApiCustomer: process.env.REACT_APP_URL_API_CUSTOMER as string,
  urlApiMenu: process.env.REACT_APP_URL_API_MENU as string,
  urlApiProduct: process.env.REACT_APP_URL_API_PRODUCT as string,
  urlApiRecapcha: process.env.REACT_APP_URL_API_RECAPTCHA as string,
  urlApiSearch: process.env.REACT_APP_URL_API_SEARCH as string,

  // GTM
  gtmId: process.env.REACT_APP_GTM_ID as string,
  gtmAuth: process.env.REACT_APP_GTM_AUTH as string,
  gtmPreview: process.env.REACT_APP_GTM_PREVIEW as string,
  gtmMediaId: process.env.REACT_APP_GTM_MEDIA_ID as string,
  gtmMediaAuth: process.env.REACT_APP_GTM_MEDIA_AUTH as string,
  gtmMediaPreview: process.env.REACT_APP_GTM_MEDIA_PREVIEW as string,

  // RECAPTCHA
  recaptchaId: process.env.REACT_APP_RECAPTCHA_ID as string,

  // MEDIAS
  mediaFooter: process.env.REACT_APP_MEDIAS_URL_FOOTER as string,
  mediaOupsBanner: process.env.REACT_APP_MEDIAS_URL_OUPS_BANNER as string,
  mediaPopin: process.env.REACT_APP_MEDIAS_URL_POPIN as string,
  mediaOctipasCms: process.env.REACT_APP_MEDIAS_URL_PAGES_CMS as string,
  mediaProductSheet: process.env.REACT_APP_MEDIAS_URL_PRODUCT_SHEET as string,
  mediaMyAccount: process.env.REACT_APP_MEDIAS_URL_ACCOUNT as string,
  mediaTwicpic: process.env.REACT_APP_TWICPICS_MEDIA_URL as string,

  // DOCKER DEV
  dockerDevEnv: process.env.REACT_APP_DOCKER_DEV as string,

  // DATETIME APP
  datetimeApp: process.env.REACT_APP_DATETIME as string,

  // TWICPICS
  baseUrlTwicpicsMedia: process.env.REACT_APP_TWICPICS as string,
  urlTwicpicsMedia: process.env.REACT_APP_TWICPICS_MEDIA_URL as string,
  urlWysiwygOctipas: process.env
    .REACT_APP_TWICPICS_WYSIWYG_OCTIPAS_URL as string,
  urlTwicpicsVideo: process.env.REACT_APP_TWICPICS_VIDEO_URL as string,
});

export default useEnv;
