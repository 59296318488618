import React, { memo } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useTranslation } from 'react-i18next';

import CategoriesTabsMobile from 'components/Common/Tabs/CategoriesTabsMobile';
import CustomLink from 'components/Common/CustomLink/CustomLink';
import { MOBILE_AND_TABLET_PORTRAIT } from 'settings/mediaQuery';
import { getUri } from 'services/generic';
import { Acp } from 'types/Acp';

import styles from '../SearchBar.module.scss';

/**
 * Display the categories returned by the search API
 */
function SearchBarCategories(props: {
  /** Categories to display */
  category?: Acp['page'];
  /** Function call to close the research suggestion panel */
  closeAcp: () => void;
}): JSX.Element | null {
  const { category, closeAcp } = props;
  const isMobileAndPortraitTablet = useMediaQuery({
    query: MOBILE_AND_TABLET_PORTRAIT,
  });
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.categories}>
        <span>{t('LABEL_CATEGORIES')}</span>
        <div className={styles.categoriesResult}>
          {isMobileAndPortraitTablet && (
            <CategoriesTabsMobile category={category} closeAcp={closeAcp} />
          )}
          {!isMobileAndPortraitTablet &&
            category?.map(page => (
              <CustomLink
                className={styles.categoryLink}
                to={getUri(page.url)}
                key={page.id}
                onClick={() => {
                  closeAcp();
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html:
                      page.highlight['label'] && page.highlight['label'][0],
                  }}
                ></div>
              </CustomLink>
            ))}
        </div>
      </div>
    </>
  );
}

export default memo(SearchBarCategories);
