import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import classnames from 'classnames';

import NavigationMenu from 'components/Menu/NavigationMenu/NavigationMenu';
import Header from 'components/Header/Header';
import { LARGE_LAPTOP_AND_DESKTOP, MOBILE, TABLET } from 'settings/mediaQuery';

import styles from './HeaderLayout.module.scss';

const DEFAULTS = {
  menuCanBeSticky: true,
};

const DEFAULTS_OPTIONS = {
  ...DEFAULTS,
  breakpoints: {},
};

// Sort items
const sortCallback = (a: string, b: string) => +a - +b;

// Transform options props to array with default and breakpoints options
const getOptions = ({
  breakpoints,
  ...opt
}: HeaderOptions = DEFAULTS_OPTIONS): [Options, BreakpointsOptions] => {
  const defaultOptions = {
    ...DEFAULTS,
    ...opt,
  };

  return [
    { ...defaultOptions },
    {
      ...Object.entries(breakpoints || {})
        .sort((a, b) => sortCallback(b[0], a[0]))
        .reduce((obj, [key, value]) => {
          obj[key] = {
            ...defaultOptions,
            ...value,
            breakpointValue: key,
          };

          return obj;
        }, {}),
    },
  ];
};

const getBreakpointOptions = (options: BreakpointsOptions) => {
  const findCallback = (breakpoint: BreakpointsOptions[0]) =>
    window.matchMedia(`(min-width: ${breakpoint.breakpointValue}px)`).matches;

  return Object.values(options).find(findCallback);
};

function HeaderLayout({ options }: { options?: HeaderOptions }): JSX.Element {
  const [displayMenu, setDisplayMenu] = useState(false);
  const [visibleSticky, setVisibleSticky] = useState(false);
  const [headerHeight, setHeaderHeight] = useState<number>(0);
  const headerRef = useRef<HTMLDivElement>(null);
  const newOptions = useMemo(() => getOptions(options), [options]);
  const { pathname } = useLocation();

  const [defaultOptions, breakpointsOptions] = newOptions;
  const [currentOptions, setCurrentOptions] = useState(
    getBreakpointOptions(breakpointsOptions) || defaultOptions
  );

  // Use mediaQuery to simplify resize detection
  const isDesktop = useMediaQuery({ query: LARGE_LAPTOP_AND_DESKTOP });
  const isTablet = useMediaQuery({ query: TABLET });
  const isMobile = useMediaQuery({ query: MOBILE });

  const canBeSticky = currentOptions.menuCanBeSticky;
  const isSticky = canBeSticky && visibleSticky;

  useEffect(() => {
    setHeaderHeight(0); // Reset header height is size of screen has changed
  }, [isDesktop, isTablet, isMobile]);

  const handleResize = () => {
    setCurrentOptions(
      getBreakpointOptions(breakpointsOptions) || defaultOptions
    );
  };

  const handleScroll = () => {
    const scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop;
    const headerRefCurrent = headerRef.current;
    const headerHeight = headerRefCurrent?.getBoundingClientRect()?.height;
    const isVisibleSticky = isDesktop ? scrollTop > 166 : scrollTop > 0;

    if (!isVisibleSticky) {
      setHeaderHeight(headerHeight || 0);
    }

    setVisibleSticky(isVisibleSticky);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    canBeSticky && document.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('resize', handleResize);
      canBeSticky && document.removeEventListener('scroll', handleScroll);
    };
  }, [currentOptions, canBeSticky]);

  useEffect(() => {
    setDisplayMenu(false);
  }, [pathname]);

  return (
    <div
      className={classnames('header', styles.header)}
      style={headerHeight > 0 ? { minHeight: `${headerHeight}px` } : undefined}
    >
      <div
        className={classnames(styles.headerContainer, {
          [styles.sticky]: isSticky,
        })}
        ref={headerRef}
        id={'headerContainerLayout'}
      >
        <Header
          displayMenu={displayMenu}
          setDisplayMenu={setDisplayMenu}
          isSticky={isSticky}
        />
        <NavigationMenu show={displayMenu} visibleSticky={isSticky} />
      </div>
    </div>
  );
}

export default HeaderLayout;

type Options = { menuCanBeSticky: boolean };
type BreakpointsOptions = Record<number, Options & { breakpointValue: number }>;
type DefaultHeaderLayoutProps = { menuCanBeSticky?: boolean };

export type HeaderOptions = DefaultHeaderLayoutProps & {
  breakpoints?: Record<number, DefaultHeaderLayoutProps>;
};
