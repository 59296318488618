import React, { useState } from 'react';
import FlashMessageContext, {
  Message,
  Severity,
} from 'contexts/flashMessageContext';

type FlashMessageProviderProps = {
  children?: React.ReactNode;
};

const FlashMessageProvider: React.FC<FlashMessageProviderProps> = ({
  children,
}) => {
  const [flashMessageState, setFlashMessageState] = useState<
    Message | undefined
  >();

  const flashMessageContext = {
    getFlashMessage: () => flashMessageState,
    setFlashMessage: (message: string, severity: Severity = 'success') => {
      setFlashMessageState({ message, severity });
    },
    flushFlashMessage: () => {
      setFlashMessageState(undefined);
    },
  };

  return (
    <FlashMessageContext.Provider value={flashMessageContext}>
      {children}
    </FlashMessageContext.Provider>
  );
};

export default FlashMessageProvider;
