import React from 'react';
import { Trans as I18nTrans } from 'react-i18next';

type TransProps = {
  i18nKey: string;
  additionalComponents?: Record<string, React.ReactNode>;
  namespace?: string;
  values?: Record<string, string>;
};

function Trans({
  i18nKey,
  additionalComponents,
  namespace,
  ...props
}: TransProps): React.ReactElement {
  const components = Object.assign(
    {},
    {
      i: <i />,
      b: <b />,
      s: <strong />,
      n: <i style={{ fontStyle: 'normal', fontWeight: 'normal' }} />,
      e: (
        <i
          style={{
            fontStyle: 'normal',
            fontWeight: '500',
            color: '#E6007E',
            fontSize: '16px',
          }}
        />
      ),
      br: <br />,
    },
    additionalComponents
  );

  return (
    <I18nTrans
      i18nKey={i18nKey}
      components={components}
      {...(namespace && { ns: namespace })}
      {...props}
    />
  );
}

export default Trans;
