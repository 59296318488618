import useSWR from 'swr';

import useEnv from 'hooks/useEnv';
import { fetcher } from 'services/fetcher';
import {
  DynamicZonesType,
  HierarchyType,
  MenuType,
  MobFooterBanner,
  ServiceBannerType,
} from 'types/menu/Menu';

function useMenu(): {
  hierarchy: HierarchyType | undefined;
  dynamicZones: DynamicZonesType | undefined;
  serviceBanner: ServiceBannerType | undefined;
  mobileMerchBanners: MobFooterBanner[] | undefined;
} {
  const { urlApiMenu } = useEnv();
  const { data: menuApiData } = useSWR<MenuType>(urlApiMenu, fetcher, {
    revalidateOnFocus: false,
  });

  return {
    hierarchy: menuApiData?.hierarchy,
    dynamicZones: Object.keys(menuApiData?.dynamicZones || {}).length
      ? menuApiData?.dynamicZones
      : undefined,
    serviceBanner: Object.keys(menuApiData?.serviceBanner || {}).length
      ? menuApiData?.serviceBanner
      : undefined,
    mobileMerchBanners: menuApiData?.mobFooterBanner,
  };
}

export default useMenu;
