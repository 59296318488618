import React, { useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';
import classnames from 'classnames';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { Options } from '@splidejs/splide';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';

import ProductCarousel from './ProductCarousel/ProductCarousel';
import CustomLink from 'components/Common/CustomLink/CustomLink';
import { getUri } from 'services/generic';
import { MOBILE_AND_TABLET_PORTRAIT } from 'settings/mediaQuery';
import { CarouselProductType } from 'types/Product';

import styles from './ProductsCarousel.module.scss';

/**
 * Display a list of products in carousel
 */
function ProductsCarousel({
  title,
  products,
  closeAcp,
  productPerPage,
  onClickProduct,
  sendEvent,
  fullWidth,
  showLabelsOnMobile,
  classes,
  splideOptions,
}: ProductsCarouselProps): JSX.Element | null {
  const windowWidth = window.innerWidth;
  const scrollbarWidth = windowWidth - document.documentElement.clientWidth;
  const wrapperStyle = {
    '--scrollbarWidth': scrollbarWidth + 'px',
  } as React.CSSProperties;

  const isMobileAndPortraitTablet = useMediaQuery({
    query: MOBILE_AND_TABLET_PORTRAIT,
  });

  if (!products?.length) {
    return null;
  }

  const options = useMemo(() => {
    return {
      ...{
        type: productPerPage >= products.length ? 'slide' : 'loop',
        margin: 0,
        rewind: false,
        perPage: productPerPage,
        perMove: 1,
        gap: fullWidth && !isMobileAndPortraitTablet ? '20px' : '10px',
        pagination: false,
        classes: {
          prev: classnames(
            'splide__arrow--prev',
            styles.prevArrowClassname,
            classes?.prevArrowClassname,
            {
              [styles.hidden]: productPerPage >= products.length,
            }
          ),
          next: classnames(
            'splide__arrow--next',
            styles.nextArrowClassname,
            classes?.nextArrowClassname,
            {
              [styles.hidden]: productPerPage >= products.length,
            }
          ),
        },
      },
      ...(splideOptions || {}),
    };
  }, [
    productPerPage,
    fullWidth,
    isMobileAndPortraitTablet,
    products,
    splideOptions,
  ]);

  return (
    <div
      className={classnames(
        styles.wrapper,
        fullWidth ? styles.full : '',
        fullWidth && classes?.wrapperFull ? classes?.wrapperFull : '',
        classes?.wrapper
      )}
      style={wrapperStyle}
    >
      {title && <h2 className={styles.title}>{title}</h2>}
      <div
        className={classnames(
          styles.carouselProductsContainer,
          classes?.carouselContainer,
          {
            [styles.showLabelsOnMobile]: showLabelsOnMobile,
          }
        )}
      >
        <Splide className="carouselProducts" options={options}>
          {products.map(product => {
            const { productId, url, shortLabel, skuId } = product;

            return (
              <SplideSlide key={productId}>
                <CustomLink
                  to={getUri(url)}
                  id={`product-carousel-${productId}`}
                  className={styles.productLink}
                  href={getUri(url)}
                  key={productId}
                  onClick={() => {
                    scrollTo(0, 0);
                    localStorage.setItem('previous-url', '');
                    if (typeof sendEvent === 'function') {
                      sendEvent(skuId ? skuId : '', shortLabel);
                    }
                    if (typeof closeAcp === 'function') {
                      closeAcp();
                    }
                    if (typeof onClickProduct === 'function') {
                      onClickProduct();
                    }
                  }}
                >
                  <ProductCarousel
                    classes={{
                      shortLabel: styles.shortLabel,
                      thumbnail: classes?.productThumbnail,
                    }}
                    product={product}
                  />
                </CustomLink>
              </SplideSlide>
            );
          })}
        </Splide>
      </div>
    </div>
  );
}

export default ProductsCarousel;

export type ProductsCarouselProps = {
  /** Title of the carousel */
  title?: string;
  /** List of products */
  products?: CarouselProductType[];
  /** Function onClose in search mobile */
  closeAcp?: () => void;
  /** Number of products display */
  productPerPage: number;
  /** Function when product is clicked */
  onClickProduct?: () => void;
  /** Function with GTM event */
  sendEvent?: (sku: string, name: string) => void;
  /** To show the carousel in full width mode */
  fullWidth?: boolean;
  /** To show the products labels/titles on mobile display */
  showLabelsOnMobile?: boolean;
  /** Additional class to carousel */
  classes?: {
    wrapper?: string;
    wrapperFull?: string;
    productThumbnail?: string;
    carouselContainer?: string;
    prevArrowClassname?: string;
    nextArrowClassname?: string;
  };
  /** Override / Additional options to splide component */
  splideOptions?: Options;
};
