import { apiUrls } from 'services/api-urls';
import { fetcher } from 'services/fetcher';
import { Search, SearchV2 } from 'types/Search';

export default async function search(
  searchKeyword: string
): Promise<{
  searchApiData: Search | SearchV2;
  redirectUrl?: string;
}> {
  return fetcher(apiUrls.getSearchApi(searchKeyword)).then(
    (searchApiData: Search | SearchV2) => {
      const redirectUrl = ('merchandising' in searchApiData
        ? (searchApiData as SearchV2).merchandising.header
        : (searchApiData as Search).Header
      )?.Controls[0].Result.Context;

      return { searchApiData, redirectUrl };
    }
  );
}

export function extractCatalogRef(search: string): string | null {
  const searchRegex = new RegExp(
    '^(?!^[A-Za-z]{7}$)[a-zA-Z0-9]{2}([a-zA-Z0-9])[. ]*([a-zA-Z0-9]{4})$'
  ).exec(search.trim());

  return searchRegex ? searchRegex[1] + searchRegex[2] : null;
}
