import { isWholeUrl } from './validation';
import { ENV_COUNTRY } from 'settings/env';
import routes from 'settings/routes';

export function isElementVisible(idElement: string): boolean {
  const element = document.getElementById(idElement);
  const rect = element?.getBoundingClientRect();
  if (rect) {
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  }
  return false;
}

export function getSimpleUri(url?: string): string {
  return `/${ENV_COUNTRY}${url}`;
}

export function getUri(url?: string): string {
  return url !== undefined && isWholeUrl(url) ? url : getSimpleUri(url);
}

export function getUriWithoutLocale(url: string): string {
  return url.replace(`/${ENV_COUNTRY}`, '');
}

export function getRoutesUri<T extends string | undefined>(
  key: string,
  uriParams?: Record<string, unknown>
): T {
  const countryCode = ENV_COUNTRY as string;
  let route = routes[countryCode]?.[key] || routes.default[key] || undefined;

  if (route && uriParams) {
    route = Object.entries(uriParams).reduce(
      (text, [property, replacement]) => {
        return text.replace(new RegExp(`:${property}`, 'g'), replacement);
      },
      route
    );
  }

  return (route ? getUri(route) : undefined) as T;
}

export function addCountryClassname(styles: Styles): string {
  return process.env.REACT_APP_COUNTRY
    ? styles[`${process.env.REACT_APP_COUNTRY}`]
    : '';
}

type Styles = {
  readonly [key: string]: string;
};
