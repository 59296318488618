import React from 'react';
import classNames from 'classnames';

import MyAccountLink from 'components/Header/RightNavigationMenu/MyAccountLink/MyAccountLink';
import HelpLink from 'components/Header/RightNavigationMenu/HelpLink/HelpLink';
import CartLink from 'components/Header/RightNavigationMenu/CartLink/CartLink';

import styles from './MobileNavigationToolbar.module.scss';

function MobileNavigationToolbar({
  classes,
}: {
  classes?: { container?: string };
}): JSX.Element {
  return (
    <div className={classNames(classes?.container, styles.userMobileMenu)}>
      <MyAccountLink
        classes={{ navLink: styles.navLink }}
        allowLoggedDropdown={false}
      />

      <HelpLink classes={{ navLink: styles.navLink }} />

      <CartLink
        classes={{
          navLink: styles.navLink,
          nbProduct: styles.nbProducts,
        }}
      />
    </div>
  );
}

export default MobileNavigationToolbar;
