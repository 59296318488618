import { useState } from 'react';

export function useModal(): {
  open: boolean;
  openModal: (e?: MouseEvent) => void;
  closeModal: () => void;
  disableBodyScroll: () => void;
  enableBodyScroll: () => void;
} {
  const [open, setOpen] = useState(false);

  const disableBodyScroll = () => {
    const body = document.querySelector('body');
    if (body) {
      body.style.overflowY = 'hidden';
    }
  };

  const enableBodyScroll = () => {
    const body = document.querySelector('body');
    if (body) {
      body.style.overflowY = 'scroll';
    }
  };

  const openModal = (e?: MouseEvent) => {
    e?.preventDefault();
    setOpen(true);
  };
  const closeModal = () => {
    setOpen(false);
    const body = document.querySelector('body');
    if (body) {
      body.style.height = 'initial';
      body.style.overflowY = 'initial';
    }
  };

  return { open, openModal, closeModal, disableBodyScroll, enableBodyScroll };
}

export function useModalMultiples(): {
  open: string;
  openModal: (idModal: string) => void;
  closeModal: () => void;
} {
  const [open, setOpen] = useState('');

  const openModal = (idModal: string) => {
    setOpen(idModal);
  };
  const closeModal = () => {
    setOpen('');
    const body = document.querySelector('body');
    if (body) {
      body.style.height = 'initial';
      body.style.overflowY = 'initial';
    }
  };

  return { open, openModal, closeModal };
}
