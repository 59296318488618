import React, { ErrorInfo } from 'react';
import { createBrowserHistory } from 'history';

import ScrollToTopButton from 'components/Common/ScrollToTopButton/ScrollToTopButton';
import AfibelFooter from 'components/AfibelFooter/AfibelFooter';
import PageNotFound from 'components/Common/Errors/PageNotFound/PageNotFound';
import { FetchError } from 'exceptions/FetchError';
import HeaderLayout from 'layout/HeaderLayout';
import { ENV_API_KEY_SYMFONY, ENV_API_URL_BROWSER_LOGGER } from 'settings/env';

type Props = {
  children: JSX.Element;
  fallback?: JSX.Element;
};

/**
 * Component displayed when an error throw.
 */
export class ErrorBoundary extends React.Component<
  Props,
  { error: FetchError | undefined; hasError: boolean }
> {
  constructor(props: Props) {
    super(props);
    this.state = { error: undefined, hasError: false };
  }

  customHistory = createBrowserHistory();

  static getDerivedStateFromError(
    error: FetchError
  ): { error: FetchError; hasError: boolean } {
    return { error: error, hasError: true };
  }

  componentDidCatch(
    error: Error,
    { componentStack }: ErrorInfo
  ): { hasError: boolean } {
    // Use XMLHttpRequest instead of fetch for compatibility with old browser
    const errAfffXhr = new XMLHttpRequest();

    errAfffXhr.open('POST', ENV_API_URL_BROWSER_LOGGER, true);
    errAfffXhr.setRequestHeader('Content-type', 'application/json');
    errAfffXhr.setRequestHeader('X-AUTH-TOKEN', ENV_API_KEY_SYMFONY);
    errAfffXhr.send(
      JSON.stringify({
        errFrom: 'errorBoundary',
        errMessage: error.message,
        errStack: error.stack,
        errStackReact: componentStack,
        userWidth: window.innerWidth,
        userHeight: window.innerHeight,
        currentUrl: window.location.href,
        timestamp: new Date().toISOString(),
      })
    );

    if (error.name === 'ChunkLoadError') {
      const url = new URL(window.location.href);
      const refreshCounterVal = url.searchParams.get('forceRefreshCounter');
      if (null === refreshCounterVal) {
        url.searchParams.set('forceRefreshCounter', '1');
        window.location.href = url.href;
      }
    }

    return { hasError: false };
  }

  render(): JSX.Element {
    const { error, hasError } = this.state;
    const { children, fallback } = this.props;

    if (hasError && fallback) {
      return fallback;
    } else if (hasError) {
      return (
        <ErrorBoundaryBlank>
          <HeaderLayout />
          <PageNotFound status={error?.status?.toString()} />
          <ScrollToTopButton />
          <AfibelFooter />
        </ErrorBoundaryBlank>
      );
    }

    return children;
  }
}

/**
 * Catch the error that may be caused in ErrorBoundary
 */
class ErrorBoundaryBlank extends React.Component<
  { children: React.ReactNode },
  { hasError: boolean }
> {
  constructor(props: { children: React.ReactNode }) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): { hasError: boolean } {
    return { hasError: true };
  }

  componentDidCatch(): { hasError: boolean } {
    return { hasError: false };
  }

  render(): React.ReactNode {
    return this.state.hasError ? <></> : this.props?.children;
  }
}
