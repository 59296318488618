export class FetchError extends Error {
  public status?: number;

  constructor(message: string, status?: number) {
    super(message);
    this.status = status;
  }

  setStatus(status?: number): FetchError {
    this.status = status;

    return this;
  }
}
