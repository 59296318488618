import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import CustomLink from 'components/Common/CustomLink/CustomLink';
import { getRoutesUri } from 'services/generic';
import { CustomerType } from 'types/Customer';

import styles from './UserDropdownMenu.module.scss';

type UserDropdownMenuProps = {
  customer?: CustomerType;
  classes?: {
    container?: string;
  };
};

function UserDropdownMenu({
  customer,
  classes,
}: UserDropdownMenuProps): JSX.Element {
  const { t } = useTranslation();
  const links: LinkType[] = [
    {
      to: getRoutesUri('customerAccountInformation') as string,
      label: t('LABEL_PERSONALINFORMATION_TITLE'),
    },
    {
      to: getRoutesUri('customerAccountOrderHistory') as string,
      label: t('LABEL_MYACCOUNT_ORDER'),
    },
    {
      to: getRoutesUri('customerAccountLogOutSuccess') as string,
      label: t('LABEL_MYACCOUNT_SIGNOUT'),
    },
  ];

  return (
    <div className={classNames(styles.container, classes?.container)}>
      <div
        className={styles.label}
      >{`${customer?.civility} ${customer?.lastName}`}</div>
      <ul>
        {links.map(link => {
          const { to, label } = link;
          return (
            <li key={label}>
              <CustomLink to={to}>{label}</CustomLink>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default UserDropdownMenu;

type LinkType = {
  to: string;
  label: string;
};
