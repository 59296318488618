import {
  ENV_COUNTRY,
  ENV_VEESUAL_API_KEY,
  ENV_VEESUAL_ENABLE,
} from 'settings/env';
import { CartProduct } from 'types/Cart';
import { ItemType } from 'types/MixAndMatch';

export const IS_ENABLE = isEnable(); // Value loaded one time.

export function isEnable(): boolean {
  const isEnable = '1' === ENV_VEESUAL_ENABLE;
  const hasApiKey = Boolean(ENV_VEESUAL_API_KEY);
  if (isEnable && !hasApiKey) {
    console.warn('Veesual is enable but no API Key is set');
  }

  return isEnable && hasApiKey;
}

export function getExperienceTag(sessionId: string): string {
  return `(function (v, ee, s, ua, l) {
    var a, t;
    a = v.createElement(ua);
    a.async = 1;
    a.src = l + '?t=' + new Date().valueOf();
    t = v.getElementsByTagName(ua)[0];
    t.parentNode.insertBefore(a, t);
    ee[s] = ee[s] || [];
  })(
    document,
    window,
    '_veesual_async',
    'script',
    'https://app.veesual.ai/vtryonxp/load.min.js'
  );
  _veesual_async.push([
    'createIframe',
    'content',
    'afibel.com',
    '1',
    '${ENV_VEESUAL_API_KEY}',
    {
      api_prefix: '/v1/afibel',
      extra_param: '${sessionId}',
      locale: '${ENV_COUNTRY}',
    },
  ])
  ;`;
}

export function getConversionTag(sessionId: string, items: ItemType[]): string {
  return `(function (v, ee, s, ua, l) {
    var a, t;
    a = v.createElement(ua);
    a.async = 1;
    a.src = l + '?t=' + new Date().valueOf();
    t = v.getElementsByTagName(ua)[0];
    t.parentNode.insertBefore(a, t);
    ee[s] = ee[s] || [];
  })(
    document,
    window,
    '_veesual_event_async',
    'script',
    'https://app.veesual.ai/vtryonxp/event.min.js'
  )
  _veesual_event_async.push([
    'createEvent',
    'CONVERSION',
    'afibel.com',
    '1',
    '${ENV_VEESUAL_API_KEY}',
    {
      api_prefix: '/v1/afibel',
      extra_param: '${sessionId}',
      items: ${JSON.stringify(items)},
    }
  ]);`;
}

export function getCTATag(
  sessionId: string,
  productId: string,
  productVariantId: string
): string {
  return `(function (v, ee, s, ua, l) {
    var a, t;
    a = v.createElement(ua);
    a.async = 1;
    a.src = l + '?t=' + new Date().valueOf();
    t = v.getElementsByTagName(ua)[0];
    t.parentNode.insertBefore(a, t);
    ee[s] = ee[s] || [];
  })(
    document,
    window,
    '_veesual_custom_cta_async',
    'script',
    'https://custom-tag.veesual.ai/afibel_pdp.js'
  )
  ;
  _veesual_custom_cta_async.push([
    'createCTA',
    'IGNORED',
    'afibel.com',
    '1',
    '${ENV_VEESUAL_API_KEY}',
    {
      api_prefix: '/v1/afibel',
      extra_param: '${sessionId}',
      product_external_id: '${productId}',
      product_external_variant_id: '${productVariantId}',
      locale: '${ENV_COUNTRY}',
    },
  ])
  ;`;
}

export function formatProducts(products: CartProduct[]): ItemType[] {
  return products.map(product => {
    return {
      sku: product.sku,
      size: product.size,
      price: product.price,
      quantity: product.quantity.toString(),
    };
  });
}
