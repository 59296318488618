import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons/faExclamationTriangle';
import { faTimes } from '@fortawesome/free-solid-svg-icons/faTimes';

import styles from './Alert.module.scss';

export type SeverityType = 'success' | 'error' | 'warning';
type AlertProps = {
  label: string;
  severity: SeverityType;
};

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  { label, severity },
  ref
) {
  const iconMap = {
    success: faCheck,
    error: faTimes,
    warning: faExclamationTriangle,
  };

  const styleMap = {
    success: styles.alertSuccess,
    error: styles.alertError,
    warning: styles.alertWarning,
  };

  const icon = severity && <FontAwesomeIcon icon={iconMap[severity]} />;
  const className = (severity && styleMap[severity]) || styles.alertWarning;

  return (
    <div className={classNames(styles.alert, className)} ref={ref}>
      {icon && <span className={styles.icon}>{icon}</span>}
      <span className={styles.label}>{label}</span>
    </div>
  );
});

export default Alert;
