import React from 'react';
import classNames from 'classnames';

import styles from './Card.module.scss';

export default function Card({
  classes,
  children,
}: React.PropsWithChildren<{
  classes?: {
    container?: string;
  };
}>): JSX.Element {
  return (
    <div className={classNames(styles.container, classes?.container)}>
      {children}
    </div>
  );
}
