import React from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons/faChevronLeft';

import Image from 'components/Common/Medias/Image';
import { ENV_TWICPICS_URL_MEDIA } from 'settings/env';
import { Navlink } from 'settings/navLinkAccountMenu';
import { getUri } from 'services/generic';

import ArrowActive from 'assets/img/Account/arrowActive.png';
import ArrowNotActive from 'assets/img/Account/arrowNotActive.png';
import PictoClose from 'assets/img/Account/croixgrise.png';

import styles from './MyAccountMenuMobile.module.scss';

function MyAccountMenuMobile({
  actionMenu,
  classes,
}: {
  actionMenu: (value: boolean) => void;
  classes?: {
    container?: string;
  };
}): JSX.Element {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div
      className={classNames(styles.customer_menu_mobile, classes?.container)}
    >
      <div className={styles.title}>
        <label>{t('LABEL_MYACCOUNT_TITLE_MOBILE')}</label>
        <img
          src={PictoClose}
          onClick={() => actionMenu(false)}
          alt="icon-close-menu"
        />
      </div>
      <div className={styles.list}>
        <ul>
          {Navlink.map(nav => {
            const { to, classname, logout, label, lng } = nav;
            const urlActive =
              window.location.href.substr(
                window.location.href.indexOf('/account/') + 9,
                5
              ) == to.substr(to.indexOf('/account/') + 9, 5);
            return (
              (!lng || lng === 'uk') && (
                <li key={to}>
                  <NavLink
                    className={
                      classname
                        ? styles.logout
                        : urlActive
                        ? styles.isActive
                        : ''
                    }
                    to={to}
                    onClick={() => actionMenu(false)}
                  >
                    {urlActive ? (
                      <img src={ArrowActive} />
                    ) : !logout && !urlActive ? (
                      <img src={ArrowNotActive} />
                    ) : (
                      logout && (
                        <Image
                          src={`${ENV_TWICPICS_URL_MEDIA}/wysiwyg/mon-compte/images/closeIcone.png`}
                          className={styles.twicImageCross}
                          ratio={''}
                          preTransform={''}
                        />
                      )
                    )}
                    <label>{label}</label>
                  </NavLink>
                </li>
              )
            );
          })}
        </ul>
      </div>
      <div className={styles.redirectionHome}>
        <p onClick={() => navigate(getUri('/'))}>
          <FontAwesomeIcon className={styles.action} icon={faChevronLeft} />
          {t('LABEL_BACK_TO_HOME')}
        </p>
      </div>
    </div>
  );
}

export default MyAccountMenuMobile;
