import React from 'react';
import classNames from 'classnames';

import CartLink from './CartLink/CartLink';
import DirectOrderLink from './DirectOrderLink/DirectOrderLink';
import HelpLink from './HelpLink/HelpLink';
import MyAccountLink from './MyAccountLink/MyAccountLink';
import SearchButton from '../SearchButton/SearchButton';

import styles from './RightNavigationMenu.module.scss';

type RightNavigationMenuProps = {
  sticky?: boolean;
  onSearchClick?: () => void;
  onSearchMouseEnter?: () => void;
  onSearchMouseLeave?: () => void;
};

/**
 * Contains global link : direct order, my account and cart.
 */
function RightNavigationMenu({
  sticky,
  onSearchClick,
}: RightNavigationMenuProps): JSX.Element {
  return (
    <div
      className={classNames(styles.navContainer, {
        [styles.sticky]: sticky,
      })}
    >
      {sticky && <SearchButton onClick={onSearchClick} />}
      {!sticky && (
        <>
          <DirectOrderLink
            classes={{
              navLink: classNames(styles.navLink, styles.directOrderLink),
            }}
          />
          <HelpLink
            classes={{ navLink: classNames(styles.navLink, styles.helpLink) }}
          />
        </>
      )}
      <MyAccountLink
        classes={{
          navLink: styles.navLink,
          containerLogged: styles.myAccountLoggedContainer,
        }}
      />
      <CartLink classes={{ navLink: styles.navLink }} />
    </div>
  );
}

export default RightNavigationMenu;
