import useEnv from './useEnv';

const useCurrentCountry = (): {
  country: string;
  isFr: boolean;
  isUk: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectForCountry: (values: { fr: any; uk: any }) => any;
} => {
  const { country } = useEnv();
  const isFr = country === 'fr';
  const isUk = country === 'uk';

  return {
    country,
    isFr,
    isUk,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    selectForCountry: (values: { fr: any; uk: any }) => {
      if (isFr) return values.fr;
      if (isUk) return values.uk;
      return null;
    },
  };
};

export default useCurrentCountry;
