import React, { Fragment } from 'react';
import classnames from 'classnames';

import CustomLink from 'components/Common/CustomLink/CustomLink';
import { getUri } from 'services/generic';
import { pushGtmEvent } from 'services/gtm/gtmEvents';
import { navigationFormatId } from 'services/string';
import { GtmMenuClick } from 'types/gtm/GtmEvent';
import { GtmEventDataType } from 'types/GtmV2/GtmPageView';
import { MenuItemType } from 'types/menu/Menu';

import styles from './MegaMenuItems.module.scss';

function MegaMenuItems({
  items,
  depth,
  onClick,
  gtmMenuClickEventData,
  prefixId,
}: {
  items: MenuItemType[];
  depth: number;
  onClick: () => void;
  gtmMenuClickEventData: GtmMenuClick;
  prefixId?: string;
}): JSX.Element {
  const handleClick = (gtmEventData: GtmEventDataType) => {
    pushGtmEvent(gtmEventData);
    onClick();
  };

  return (
    <>
      {Object.keys(items).map(key => {
        const item: MenuItemType = items[key];
        const { children, label, metadata, url } = item;
        const id = `${prefixId}_${navigationFormatId(label)}`;
        const { menuNoClick, cssClass, menuNoLayer } = metadata;
        if (menuNoLayer) {
          return false;
        }

        const gtmMenuClickEventDataUpdated = {
          ...gtmMenuClickEventData,
          ...{ [`menu_level_${depth + 2}`]: label.toLowerCase() },
        };

        return (
          <Fragment key={id}>
            <CustomLink
              id={id}
              to={getUri(url)}
              className={classnames(styles.link, cssClass || undefined, {
                [styles.disabled]: menuNoClick,
              })}
              onClick={() => handleClick(gtmMenuClickEventDataUpdated)}
            >
              {label}
            </CustomLink>
            {children && (
              <MegaMenuItems
                items={children}
                depth={depth + 1}
                onClick={onClick}
                gtmMenuClickEventData={gtmMenuClickEventDataUpdated}
                prefixId={id}
              />
            )}
          </Fragment>
        );
      })}
    </>
  );
}

export default MegaMenuItems;
