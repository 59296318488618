import React from 'react';
import classNames from 'classnames';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';

import ScrollToTopButton from 'components/Common/ScrollToTopButton/ScrollToTopButton';
import HeaderLayout, { HeaderOptions } from 'layout/HeaderLayout';
import useEnv from 'hooks/useEnv';

import styles from './PageLayout.module.scss';

function PageLayout({
  children,
  emptyLayout = false,
  recaptcha = false,
  headerOptions,
  classes,
}: {
  children:
    | React.LazyExoticComponent<() => JSX.Element | null>
    | JSX.Element
    | null;
  emptyLayout?: boolean;
  recaptcha?: boolean;
  headerOptions?: HeaderOptions;
  classes?: { container?: string };
}): JSX.Element {
  const { recaptchaId } = useEnv();
  const AfibelFooter = React.lazy(
    () => import('components/AfibelFooter/AfibelFooter')
  );

  function render(): JSX.Element {
    return (
      <>
        <div>
          {!emptyLayout && <HeaderLayout options={headerOptions} />}
          <div
            className={classNames(
              styles.container,
              classes?.container
                ? styles[`container-${classes?.container}`]
                : undefined
            )}
          >
            {children}
          </div>
          <ScrollToTopButton />
        </div>
        <AfibelFooter />
      </>
    );
  }

  return (
    <>
      {recaptcha ? (
        <GoogleReCaptchaProvider reCaptchaKey={recaptchaId}>
          {render()}
        </GoogleReCaptchaProvider>
      ) : (
        render()
      )}
    </>
  );
}

export default PageLayout;
