import { t } from 'i18next';
import { getRoutesUri } from 'services/generic';

const BREADCRUMB_PATHS: BreadcrumbConfig = {
  [getRoutesUri<string>('customerLogin')]: {
    style: { maxWidth: 1084, width: 'auto', padding: 0 },
    items: [{ label: t('LABEL_IDENTIFICATION') }],
  },
  [getRoutesUri<string>('customerSignup')]: {
    style: { maxWidth: 1084, width: 'auto', padding: 0 },
    items: [{ label: t('BREADCRUMB_SIGNUP_STEP') }],
  },
  [getRoutesUri<string>('customerForgotPasswordVerification')]: {
    style: { maxWidth: 1084, width: 'auto', padding: 0 },
    items: [
      {
        label: t('LABEL_IDENTIFICATION'),
        url: getRoutesUri<string>('customerLogin'),
      },
      { label: t('LABEL_LAYOUT_THIRD_STEP_RESET') },
    ],
  },
  [getRoutesUri<string>('customerAccountInformation')]: {
    items: [
      {
        label: t('LABEL_MY_ACCOUNT'),
        url: getRoutesUri<string>('customerAccountOrderHistory'),
      },
      { label: t('LABEL_PERSONALINFORMATION_TITLE') },
    ],
  },
  [getRoutesUri<string>('customerAccountInformationEdit')]: {
    items: [
      {
        label: t('LABEL_MY_ACCOUNT'),
        url: getRoutesUri<string>('customerAccountOrderHistory'),
      },
      { label: t('LABEL_PERSONALINFORMATION_TITLE') },
    ],
  },
  [getRoutesUri<string>('customerAccountInformationEditEmail')]: {
    items: [
      {
        label: t('LABEL_MY_ACCOUNT'),
        url: getRoutesUri<string>('customerAccountOrderHistory'),
      },
      {
        label: t('LABEL_PERSONALINFORMATION_TITLE'),
        url: getRoutesUri<string>('customerAccountInformationEdit'),
      },
      { label: t('LABEL_LAYOUT_FOURTH_STEP_EMAIL') },
    ],
  },
  [getRoutesUri<string>('customerAccountInformationEditPassword')]: {
    items: [
      {
        label: t('LABEL_MY_ACCOUNT'),
        url: getRoutesUri<string>('customerAccountOrderHistory'),
      },
      {
        label: t('LABEL_PERSONALINFORMATION_TITLE'),
        url: getRoutesUri<string>('customerAccountInformationEdit'),
      },
      { label: t('LABEL_LAYOUT_FOURTH_STEP_PASSWORD') },
    ],
  },
  [getRoutesUri<string>('customerAccountAddressMainEdit')]: {
    items: [
      {
        label: t('LABEL_MY_ACCOUNT'),
        url: getRoutesUri<string>('customerAccountOrderHistory'),
      },
      {
        label: t('LABEL_PERSONALINFORMATION_TITLE'),
        url: getRoutesUri<string>('customerAccountInformation'),
      },
      { label: t('LABEL_LAYOUT_FOURTH_STEP_MAINADDRESS') },
    ],
  },
  [getRoutesUri<string>('customerAccountAddressSecondaryEdit')]: {
    items: [
      {
        label: t('LABEL_MY_ACCOUNT'),
        url: getRoutesUri<string>('customerAccountOrderHistory'),
      },
      {
        label: t('LABEL_PERSONALINFORMATION_TITLE'),
        url: getRoutesUri<string>('customerAccountInformation'),
      },
      { label: t('LABEL_LAYOUT_FOURTH_STEP_OPTIONALADDRESS') },
    ],
  },
  [getRoutesUri<string>('customerAccountOrderHistory')]: {
    items: [
      {
        label: t('LABEL_MY_ACCOUNT'),
        url: getRoutesUri<string>('customerAccountOrderHistory'),
      },
      { label: t('LABEL_MYACCOUNT_ORDER') },
    ],
  },
  [getRoutesUri<string>('customerAccountOrderDetails')]: {
    items: [
      {
        label: t('LABEL_MY_ACCOUNT'),
        url: getRoutesUri<string>('customerAccountOrderHistory'),
      },
      {
        label: t('LABEL_MYACCOUNT_ORDER'),
        url: getRoutesUri<string>('customerAccountOrderHistory'),
      },
      { label: t('LABEL_LAYOUT_FOURTH_STEP_ORDERDETAILS') },
    ],
  },
  [getRoutesUri<string>('customerAccountOrderEvtDetails')]: {
    items: [
      {
        label: t('LABEL_MY_ACCOUNT'),
        url: getRoutesUri<string>('customerAccountOrderHistory'),
      },
      {
        label: t('LABEL_MYACCOUNT_ORDER'),
        url: getRoutesUri<string>('customerAccountOrderHistory'),
      },
      { label: t('LABEL_LAYOUT_FOURTH_STEP_ORDERDETAILS') },
    ],
  },
  [getRoutesUri<string>('customerAccountNewsletter')]: {
    items: [
      {
        label: t('LABEL_MY_ACCOUNT'),
        url: getRoutesUri<string>('customerAccountOrderHistory'),
      },
      { label: t('LABEL_SUMMARY_SUBSCRIPTION_EDIT') },
    ],
  },
  [getRoutesUri<string>('customerAccountCatalogRequest')]: {
    items: [
      {
        label: t('LABEL_MY_ACCOUNT'),
        url: getRoutesUri<string>('customerAccountOrderHistory'),
      },
      {
        label: t('LABEL_MYACCOUNT_CATALOG'),
      },
    ],
  },
  [getRoutesUri<string>('customerAccountPersonalAccount')]: {
    items: [
      {
        label: t('LABEL_MY_ACCOUNT'),
        url: getRoutesUri<string>('customerAccountOrderHistory'),
      },
      { label: t('LABEL_PERSONAL_ACCOUNT_TITLE') },
    ],
  },
};

export default BREADCRUMB_PATHS;

type BreadcrumbConfig = {
  [path: string]: {
    style?: Record<string, unknown>;
    items: {
      label: string;
      url?: string;
    }[];
  };
};
