import slugify from 'slugify';

export function switchSinglePlural(
  value: number | undefined,
  single: string,
  plural: string,
  displaySingleValue = true
): string | null {
  if (value && isNaN(value)) {
    return null;
  }
  return value && value > 1
    ? `${value} ${plural}`
    : `${displaySingleValue ? `${value} ` : ''}${single}`;
}

/**
 * Adapt value to use in ID attr
 */
export function navigationFormatId(value: string): string {
  return slugify(value, { lower: true, strict: true });
}

/**
 * Can remove specific characters from the beginning and end of text
 */
export function trim(str: string, chars: string | string[]): string {
  let start = 0;
  let end = str.length;

  while (start < end && chars.indexOf(str[start]) >= 0) ++start;

  while (end > start && chars.indexOf(str[end - 1]) >= 0) --end;

  return start > 0 || end < str.length ? str.substring(start, end) : str;
}

export function replaceLastOccurrence(
  str: string,
  search: string,
  replace: string
): string {
  const lastIndex = str.lastIndexOf(search);
  if (lastIndex === -1) {
    return str;
  }

  return (
    str.substring(0, lastIndex) +
    replace +
    str.substring(lastIndex + search.length)
  );
}
