import React, { useEffect, useState } from 'react';

import { userContext } from 'contexts/userContext';
import { useCookies } from 'react-cookie';
import { GtmUserCookieType } from 'types/GtmUserCookie';
import { useCustomer } from 'hooks/useCustomer';

function UserProvider(props: { children: JSX.Element }): JSX.Element {
  const { children } = props;
  const [retarg, setRetarg] = useState<string | undefined>(undefined);
  const [cUserId, setCUserId] = useState<string | undefined>(undefined);
  const [gtmUserCookie, setGtmUserCookie] = useState<
    GtmUserCookieType | undefined
  >(undefined);

  const { customer } = useCustomer();
  const [cookies] = useCookies();

  useEffect(() => {
    if (customer !== undefined) {
      if (
        (cookies.retarg && !retarg) ||
        (retarg && cookies.retarg !== retarg)
      ) {
        setRetarg(cookies.retarg);
        fetch(`${process.env.REACT_APP_URL_API_CUSTOMER}/updateSession`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            'X-AUTH-TOKEN': `${process.env.REACT_APP_API_SYMFONY_API_KEY}`,
          },
          body: JSON.stringify({
            retarg: cookies.retarg,
          }),
        }).then(response => {
          if (response) {
            response.json().then(data => {
              if (data) {
                const cuserId = data?.OCTIPASS?.match(
                  /[a-zA-Z0-9]{8}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{4}-[a-zA-Z0-9]{12}/
                );
                cUserId?.length === 1 ? setCUserId(cuserId[0]) : null;
              }
            });
          }
        });
        fetch(`${process.env.REACT_APP_URL_API_CUSTOMER}/user/retarg`, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'X-AUTH-TOKEN': `${process.env.REACT_APP_API_SYMFONY_API_KEY}`,
          },
          body: JSON.stringify({
            retarg: cookies.retarg,
          }),
        }).then(response => {
          if (response) {
            response.json().then(data => {
              if (data.CODE_RETOUR === '0') {
                const {
                  NBCDE: nbCommand,
                  OPTIONMAIL: optionEmail,
                  DATEDERNCDE: dateLastCommand,
                  CATEGORIE: category,
                } = data;
                setGtmUserCookie({
                  user_id: (cookies.retarg || '').toString(),
                  user_logged: customer?.isLoggedIn ? 'yes' : 'no',
                  user_email_optin: optionEmail === 'O' ? 'yes' : 'no',
                  user_nb_command: nbCommand,
                  user_status: nbCommand > 0 ? 'client' : 'prospect',
                  customer_last_order: dateLastCommand,
                  user_category:
                    category.length > 0 ? category?.slice(0, 1) : 1,
                });
              }
            });
          }
        });
      } else if (!retarg) {
        setRetarg('');
      }
    }
  }, [cookies, location.pathname, customer]);

  useEffect(() => {
    if (cookies['gtm-user-cookie']) {
      setGtmUserCookie(cookies['gtm-user-cookie']);
    }
  }, [cookies]);

  return (
    <userContext.Provider
      value={{
        retarg,
        cUserId,
        gtmUserCookie,
      }}
    >
      {children}
    </userContext.Provider>
  );
}

export default UserProvider;
