import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Alert from 'components/Common/Alert/Alert';
import { ScrollToTop } from 'components/Common/ScrollToTop/ScrollToTop';

import FlashMessageContext from 'contexts/flashMessageContext';

const FlashMessageRender = (): JSX.Element | null => {
  const { getFlashMessage, flushFlashMessage } = useContext(
    FlashMessageContext
  );

  const { pathname, state } = useLocation();
  const navigate = useNavigate();
  const [previousPathname, setPreviousPathname] = useState(pathname);
  const stateFlashMessage = useMemo(() => state?.flashMessage, [pathname]);
  const { message, severity } = getFlashMessage() || stateFlashMessage || {};

  useEffect(() => {
    if (state?.flashMessage) {
      navigate(pathname, {
        replace: true,
        state: { ...state, flashMessage: undefined },
      });
    }

    if (previousPathname !== pathname) {
      flushFlashMessage();
      setPreviousPathname(pathname);
    }
  }, [pathname, previousPathname]);

  return message ? (
    <>
      <Alert label={message} severity={severity} />
      <ScrollToTop />
    </>
  ) : null;
};

export default React.memo(FlashMessageRender);
