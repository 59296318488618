import React from 'react';
import { useTranslation } from 'react-i18next';

import CustomLink from 'components/Common/CustomLink/CustomLink';
import Trans from 'components/Common/Translation/Trans';
import useCurrentCountry from 'hooks/useCurrentCountry';
import { getUri } from 'services/generic';
import { pushGtmEvent } from 'services/gtm/gtmEvents';

import QuickOrderPicto from 'assets/img/headerV2/catalog.svg';

type DirectOrderLinkProps = {
  onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
  classes?: {
    navLink?: string;
    image?: string;
  };
};

function DirectOrderLink({
  classes,
  onClick,
}: DirectOrderLinkProps): JSX.Element {
  const { t } = useTranslation();
  const { isFr } = useCurrentCountry();
  const handleClick = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    onClick && onClick(e);
    pushGtmEvent({
      event: '_header_click',
      category: 'header',
      element: 'commande direct',
    });
  };

  return (
    <CustomLink
      className={classes?.navLink}
      to={getUri(isFr ? '/commande_directe' : '/direct_order')}
      title={t('LABEL_ORDERING_CATALOG_REF')}
      onClick={handleClick}
    >
      <img
        src={QuickOrderPicto}
        alt="quick-order"
        className={classes?.image}
        width={33}
        height={28}
      />
      <span>
        <Trans i18nKey={'LABEL_QUICK_ORDER'} />
      </span>
    </CustomLink>
  );
}

export default DirectOrderLink;
