import React from 'react';

import Modal from 'components/Common/Modal/Modal';
import Loader from 'components/Common/Loader/Loader';

/**
 * Modal with loader
 */
function ModalLoading({
  isOpen,
}: {
  /** Display modal */
  isOpen: boolean;
}): JSX.Element | null {
  return (
    <Modal isOpen={isOpen} noBackground>
      <div>
        <Loader />
      </div>
    </Modal>
  );
}

export default ModalLoading;
