import React, { useCallback, useRef } from 'react';
import classNames from 'classnames';

import MenuItem, { MenuItemProps } from './MenuItem/MenuItem';
import useMenu from 'hooks/Menu/useMenu';

import styles from './Menu.module.scss';

type MenuProps = {
  classes?: {
    menuContainer?: string;
    menuItem?: MenuItemProps['classes'];
  };
};

function Menu({ classes }: MenuProps): JSX.Element {
  const { hierarchy, dynamicZones, serviceBanner } = useMenu();
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const isOpenRef = useRef(false);
  const menuItemClasses = { ...classes?.menuItem };

  const delayOpening = useCallback((callback: () => void): void => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);

    if (!isOpenRef.current) {
      timeoutRef.current = setTimeout(() => {
        callback();
        isOpenRef.current = true;
      }, 300);
    } else {
      callback();
    }
  }, []);

  menuItemClasses.container = classNames(
    menuItemClasses?.container,
    styles.menuItem
  );

  function resetOpenRef(): void {
    timeoutRef.current && clearTimeout(timeoutRef.current);
    isOpenRef.current = false;
  }

  return (
    <ul
      className={classNames(styles.container, classes?.menuContainer)}
      onMouseLeave={resetOpenRef}
    >
      {hierarchy?.map(item => {
        const menuNoLayer = item.metadata.menuNoLayer || false;
        if (menuNoLayer) {
          return false;
        }

        return (
          <MenuItem
            key={item.id}
            item={item}
            banners={dynamicZones && dynamicZones[item.id]}
            serviceBanner={serviceBanner}
            delayOpening={delayOpening}
            classes={menuItemClasses}
          />
        );
      })}
    </ul>
  );
}

export default Menu;
