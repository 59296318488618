import React from 'react';

import useEnv from 'hooks/useEnv';
import { useMediaServer } from 'hooks/useMediaServer';
import { replaceByLinkEnv } from 'services/formatter';

const PageNotFoundBanner = (): JSX.Element | null => {
  const { mediaOupsBanner } = useEnv();
  const { content: data } = useMediaServer(mediaOupsBanner);

  if (!data) return null;

  return (
    <div dangerouslySetInnerHTML={{ __html: replaceByLinkEnv(data) }}></div>
  );
};

export default PageNotFoundBanner;
