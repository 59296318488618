import React, { useRef, useState } from 'react';
import classnames from 'classnames';

import Menu from '../Menu';
import SearchBar, { SearchBarRefType } from 'components/SearchBar/SearchBar';
import RightNavigationMenu from 'components/Header/RightNavigationMenu/RightNavigationMenu';

import styles from './NavigationMenu.module.scss';

function NavigationMenuDesktop({
  sticky,
}: {
  sticky: boolean;
}): JSX.Element | null {
  const searchBarRef = useRef<SearchBarRefType>(null);
  const [openSearch, setOpenSearch] = useState<boolean>(false);
  const openSearchBar = () => {
    setOpenSearch(true);
  };
  const closeSearchBar = () => {
    searchBarRef.current?.clearSearchInput();
    setOpenSearch(false);
  };

  return (
    <div
      className={classnames(styles.container, {
        [styles.sticky]: sticky,
        [styles.searchOpen]: openSearch,
      })}
    >
      <div className={styles.navigation}>
        <Menu
          classes={{
            menuContainer: styles.menu,
            menuItem: {
              container: styles.menuItem,
            },
          }}
        />
        <div className={styles.rightSide}>
          <SearchBar
            ref={searchBarRef}
            sticky={sticky}
            onFocus={openSearchBar}
            onClickAway={closeSearchBar}
            onClose={closeSearchBar}
            onRedirect={closeSearchBar}
            classes={{
              container: styles.searchBar,
              inputGroup: styles.searchInputGroup,
            }}
          />
          <div className={styles.rightNavigation}>
            <RightNavigationMenu
              sticky={sticky}
              onSearchClick={openSearchBar}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default NavigationMenuDesktop;
