import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import classnames from 'classnames';
import { ClickAwayListener } from '@material-ui/core';

import styles from './Modal.module.scss';

type Props = React.PropsWithChildren<{
  /** Define to show modal */
  isOpen: boolean;
  /** Show background */
  noBackground?: boolean;
  /** Overloading css class of modal container */
  modalClassName?: string;
  /** Append the modal directly in the body */
  appendInBody?: boolean;
  /** Action on click outside */
  onClickAway?: () => void;
}>;

/**
 * Modal container
 */
function Modal({
  children,
  isOpen,
  noBackground = false,
  modalClassName,
  appendInBody = false,
  onClickAway,
}: Props): JSX.Element | null {
  useEffect(() => {
    const body = document.querySelector('body');
    if (body) {
      if (isOpen) {
        body.style.height = '100vh';
        body.style.overflowY = 'hidden';
        return;
      }
    }
  }, [isOpen]);

  if (!isOpen) {
    return null;
  }

  const renderModalContainer = () => {
    return (
      <div
        className={classnames(
          styles.modalContainer,
          {
            [styles.noBackground]: noBackground,
          },
          modalClassName
        )}
      >
        {children}
      </div>
    );
  };

  const renderModal = () => {
    return (
      <>
        {onClickAway ? (
          <ClickAwayListener onClickAway={() => onClickAway()}>
            {renderModalContainer()}
          </ClickAwayListener>
        ) : (
          renderModalContainer()
        )}
        <div className={styles.overlay}></div>
      </>
    );
  };

  if (appendInBody) {
    return <>{createPortal(renderModal(), document.body)}</>;
  }

  return <>{renderModal()}</>;
}

export default Modal;
