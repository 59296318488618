import { createContext } from 'react';

export type Severity = 'success' | 'error';
export type Message = {
  message: string;
  severity: Severity;
};

export type FlashMessageContextType = {
  getFlashMessage: () => Message | undefined;
  setFlashMessage: (message: string, severity?: Severity) => void;
  flushFlashMessage: () => void;
};

export const FLASH_MESSAGE_STATE_KEY = 'flashMessage';

const FlashMessageContext = createContext<FlashMessageContextType>({
  getFlashMessage: (): Message | undefined => {
    return;
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  setFlashMessage: (message: string, severity: Severity = 'success'): void => {
    return;
  },
  flushFlashMessage: () => {
    return;
  },
});

export default FlashMessageContext;
