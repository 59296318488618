export const PL_TEMPLATE1_SLOT1_POSITION_DESKTOP = 4;
export const PL_TEMPLATE1_SLOT1_POSITION_MOBILE = 4;
export const PL_TEMPLATE1_SLOT2_POSITION_DESKTOP = 15; // 16 - 1
export const PL_TEMPLATE1_SLOT2_POSITION_MOBILE = 15;
export const PL_TEMPLATE1_SLOT3_POSITION_DESKTOP = 19; // 21 - 2
export const PL_TEMPLATE1_SLOT3_POSITION_MOBILE = 19;

export const PL_TEMPLATE2_PRODUIT_GRAND1_POSITION_DESKTOP = 7;
export const PL_TEMPLATE2_PRODUIT_GRAND1_POSITION_MOBILE = 7;
export const PL_TEMPLATE2_PRODUIT_GRAND2_POSITION_DESKTOP = 18; // 19 - 1
export const PL_TEMPLATE2_PRODUIT_GRAND2_POSITION_MOBILE = 17;
export const PL_TEMPLATE2_PRODUIT_GRAND2_POSITION_TABLET_PORTRAIT = 16;
export const PL_TEMPLATE2_PRODUIT_GRAND3_POSITION_DESKTOP = 29; // 31 - 2
export const PL_TEMPLATE2_PRODUIT_GRAND3_POSITION_MOBILE = 29;

export const MAX_PRODUCTS_HPPush1Produit = 10;
export const MAX_PRODUCTS_HPPush2Produit = 10;
export const MAX_PRODUCTS_FPComplementaire = 10;
export const MAX_PRODUCTS_FPSimilaire = 10;
export const MAX_PRODUCTS_TotalLook = 4;
export const MAX_PRODUCTS_PopinAjoutProduit = 10;
export const MAX_PRODUCTS_PanierPush1Produit = 10;
export const MAX_PRODUCTS_PLTemplate1ProduitTop = 6;
export const MAX_PRODUCTS_SearchNoResultDecouvrezAussiProduit = 10;
export const MAX_PRODUCTS_SearchNoResultVousAimerezProduit = 10;
