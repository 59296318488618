import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';

import CustomLink from 'components/Common/CustomLink/CustomLink';
import { getUri } from 'services/generic';
import { Acp } from 'types/Acp';

/**
 * Component to display categories in SearchBar on mobile
 */
function CategoriesTabsMobile(props: {
  /** Categories to display */
  category?: Acp['page'];
  /** Function call to close the research suggestion panel */
  closeAcp: () => void;
  /** GTM event function */
  sendEvent?: (categoryLabel: string) => void;
}): JSX.Element {
  const { category, closeAcp, sendEvent } = props;

  return (
    <div>
      <AppBar position="static" color="default">
        <Tabs
          value={0}
          variant="scrollable"
          scrollButtons="off"
          TabIndicatorProps={{
            style: {
              display: 'none',
            },
          }}
        >
          {category?.map(category => (
            <CustomLink
              to={getUri(category.url)}
              key={category.id}
              onClick={() => {
                if (typeof sendEvent === 'function') sendEvent(category.label);
                closeAcp();
              }}
            >
              <div
                dangerouslySetInnerHTML={{
                  __html:
                    category.highlight['label'] &&
                    category.highlight['label'][0],
                }}
              ></div>
            </CustomLink>
          ))}
        </Tabs>
      </AppBar>
    </div>
  );
}

export default CategoriesTabsMobile;
