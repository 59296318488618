import { ProductDetail } from 'types/Controls/ProductSheet';

export function getMerchActionProducts(
  actions: MerchActions | undefined,
  maxProducts: number
): ProductDetail[] {
  return (
    actions
      ?.sort((actionsA, actionsB) => actionsA.Priority - actionsB.Priority)
      .reverse()
      .flatMap(actions => actions.Products)
      .filter(
        (value, indexProduct, products) =>
          products.findIndex(product => product.Id === value.Id) ===
          indexProduct
      )
      .slice(0, maxProducts) || []
  );
}

export type MerchActions = {
  __type:
    | 'ProductPushMerchandisingAction:Compario.FrontAPI.ContentModels'
    | 'RelatedProductMerchandisingAction:Compario.FrontAPI.ContentModels'
    | 'SimilarProductMerchandisingAction:Compario.FrontAPI.ContentModels'
    | 'TotalLookMerchandisingAction:Compario.FrontAPI.ContentModels';
  Id: string | number;
  Label: string;
  FrontLabel: string;
  Position: number;
  Priority: number;
  CustomData: unknown[];
  Metadata: { Label: string; Value: string }[];
  Products: ProductDetail[];
}[];
