import { ENV_REACT_URL_CUSTOMER } from './env';

// Define all react routes by key here
export default {
  default: {
    productSitemap: '/site-plan-products.html',
    categorySitemap: '/site-plan-categories.html',
    customerLogin: `${ENV_REACT_URL_CUSTOMER}/login`,
    customerSignup: `${ENV_REACT_URL_CUSTOMER}/create`,
    customerForgotPasswordVerification: `${ENV_REACT_URL_CUSTOMER}/forgotpasswordverification`,
    customerAccountInformation: `${ENV_REACT_URL_CUSTOMER}/informations`,
    customerAccountInformationEdit: `${ENV_REACT_URL_CUSTOMER}/informations/edit`,
    customerAccountInformationEditEmail: `${ENV_REACT_URL_CUSTOMER}/informations/edit/email`,
    customerAccountInformationEditPassword: `${ENV_REACT_URL_CUSTOMER}/informations/edit/password`,
    customerAccountAddressMainEdit: `${ENV_REACT_URL_CUSTOMER}/informations/address/main/edit`,
    customerAccountAddressSecondaryEdit: `${ENV_REACT_URL_CUSTOMER}/informations/address/secondary/edit`,
    customerAccountOrderHistory: `${ENV_REACT_URL_CUSTOMER}/order/history`,
    customerAccountOrderDetails: `${ENV_REACT_URL_CUSTOMER}/order/details/:id`,
    customerAccountOrderEvtDetails: `${ENV_REACT_URL_CUSTOMER}/order/evt_details/:id`,
    customerAccountNewsletter: `${ENV_REACT_URL_CUSTOMER}/newsletter`,
    customerAccountCatalogRequest: `${ENV_REACT_URL_CUSTOMER}/catalogrequest`,
    customerAccountLogOutSuccess: `${ENV_REACT_URL_CUSTOMER}/logoutSuccess`,
    customerAccountPersonalAccount: `${ENV_REACT_URL_CUSTOMER}/my-personal-account`,
    cart: '/checkout/cart',
    checkoutLogin: `/checkout/account/login`,
    checkoutSignup: `/checkout/account/create`,
    checkoutShipping: `/checkout/shipping`,
    privacyPolicy: '/privacy-policy',
    promoOffers: '/promotional-offers-terms-and-conditions',
    directOrder: '/direct_order',
  },
  fr: {
    productSitemap: '/plan-de-site-produits.html',
    categorySitemap: '/plan-de-site-categories.html',
    privacyPolicy: '/donnees-personnelles-cookies',
    directOrder: '/commande_directe',
  },
};
