// eslint-disable-next-line  @typescript-eslint/no-explicit-any
import { FetchError } from 'exceptions/FetchError';

export const fetcher = async (
  url: string,
  returnDirectResponse = false,
  additionalRequestParams: RequestInit = {}
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const params: RequestInit = {
    credentials: 'include',
    headers: {
      'X-AUTH-TOKEN': `${process.env.REACT_APP_API_SYMFONY_API_KEY}`,
    },
    ...additionalRequestParams,
  };
  const res = await fetch(url, params);

  if (returnDirectResponse) {
    return res;
  }

  if (!res.ok) {
    throw new FetchError(
      `An error occurred while fetching the data ${url}`,
      res.status
    );
  }

  return await res.json();
};

// eslint-disable-next-line  @typescript-eslint/no-explicit-any
export const fetcherWithBody = async (
  url: string,
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types, @typescript-eslint/no-explicit-any
  bodyContent: any,
  returnDirectResponse = false,
  additionalRequestParams: RequestInit = {}
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): Promise<any> => {
  const params: RequestInit = {
    method: 'POST',
    credentials: 'include',
    body: JSON.stringify(bodyContent),
    headers: {
      'Content-Type': 'application/json',
      'X-AUTH-TOKEN': `${process.env.REACT_APP_API_SYMFONY_API_KEY}`,
    },
    ...additionalRequestParams,
  };

  const res = await fetch(url, params);
  if (returnDirectResponse) {
    return res;
  }
  if (!res.ok) {
    const error = new Error(
      `An error occurred while fetching the data ${url}`
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ) as any;
    error.info = await res.json();
    error.status = res.status;
    throw error;
  }
  return await res.json();
};

/**
 * Use this function when the response is text / html
 */
export const htmlFetcher = async (
  url: string,
  additionalRequestParams: RequestInit = {}
): Promise<string> => {
  const res = await fetch(url, {
    credentials: 'include',
    ...additionalRequestParams,
  });

  if (!res.ok) {
    throw new FetchError(
      'An error occurred while fetching the data.',
      res.status
    );
  }

  return res.text();
};
