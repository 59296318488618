import React from 'react';
import useSWR from 'swr';
import { fetcher } from 'services/fetcher';
import { Site } from 'types/Site';
import useEnv from 'hooks/useEnv';

/**
 * Header banner - Display at the top of the website. Return by Site.cxml API.
 */
function HeaderBanner(): JSX.Element {
  const { datetimeApp } = useEnv();
  const { data } = useSWR<Site>(
    `${process.env.REACT_APP_URL_API_CATALOG}/json/Custom/Site.cxml?${datetimeApp}`,
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      refreshInterval: 0,
    }
  );
  const htmlContent = data?.Zones[0]?.Controls[0]?.Actions[0]?.HtmlContent;

  return (
    <div
      id={'headerBanner'}
      dangerouslySetInnerHTML={{ __html: htmlContent }}
    />
  );
}

export default HeaderBanner;
