import React from 'react';
import classNames from 'classnames';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import CustomLink from 'components/Common/CustomLink/CustomLink';
import { getUri } from 'services/generic';
import { hasAtLeastOneItemsVisible } from 'services/menu/menu';
import { navigationFormatId } from 'services/string';
import { isReactPage } from 'services/validation';
import { MenuItemType } from 'types/menu/Menu';

import styles from './MenuMobile.module.scss';

export type MenuItemClasses = {
  link?: string;
};

type MenuItemProps = {
  item: MenuItemType;
  goTo: (item: MenuItemType) => void;
  classes?: MenuItemClasses;
  itemPrefixId: string;
  onClick: (currentLabel: string) => void;
};

function MenuItem({
  item,
  goTo,
  classes,
  itemPrefixId,
  onClick,
}: MenuItemProps): JSX.Element {
  const { label, url, children, metadata } = item;
  const { cssClass } = metadata;

  function htmlLink(
    label: string,
    url: string,
    className?: string,
    id?: string
  ) {
    return (
      <a
        className={classNames(styles.button, className)}
        key={url}
        href={getUri(url)}
        id={`${id}_${navigationFormatId(label)}`}
        onClick={() => onClick(label)}
      >
        <span>{label}</span>
        <ArrowForwardIosIcon className={styles.arrow} />
      </a>
    );
  }

  function reactLink(
    label: string,
    url: string,
    className?: string,
    prefixId?: string
  ) {
    return (
      <CustomLink
        className={classNames(styles.button, className)}
        key={url}
        to={getUri(url)}
        id={`${prefixId}_${navigationFormatId(label)}`}
        onClick={() => onClick(label)}
      >
        <span>{label}</span>
        <ArrowForwardIosIcon className={styles.arrow} />
      </CustomLink>
    );
  }

  if (!hasAtLeastOneItemsVisible(children || [], 'menuMobNoLayer')) {
    return isReactPage(url)
      ? reactLink(
          label,
          url,
          classNames(cssClass, styles.categoryLink, classes?.link),
          itemPrefixId
        )
      : htmlLink(
          label,
          url,
          classNames(cssClass, styles.categoryLink, classes?.link),
          itemPrefixId
        );
  }

  return (
    <button
      className={classNames(cssClass, styles.button, classes?.link)}
      type={'button'}
      onClick={() => goTo(item)}
      id={`${itemPrefixId}_${navigationFormatId(label)}`}
    >
      <span>{label}</span>
      <ArrowForwardIosIcon className={styles.arrow} />
    </button>
  );
}

export default MenuItem;
