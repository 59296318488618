import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { installTwicPics } from '@twicpics/components/react';

import Router from 'router/Router';

import '@twicpics/components/style.css';
import './App.scss';

function App(): JSX.Element {
  const { t } = useTranslation();

  installTwicPics({
    domain: `${process.env.REACT_APP_TWICPICS}`,
  });

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link
          rel="stylesheet"
          href={`${process.env.REACT_APP_WYSIWYG_OCTIPAS_URL}/style-afibel-merch.css?${process.env.REACT_APP_DATETIME}`}
        />
        <link
          rel="stylesheet"
          href={`${process.env.REACT_APP_MEDIAS_URL_PAGES_CMS}/style-afibel-cms.css?${process.env.REACT_APP_DATETIME}`}
        />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#ffffff" />
        <meta name="description" content={t('DEFAULT_HEAD_META_DESCRIPTION')} />
        <meta name="keywords" content={t('DEFAULT_HEAD_META_KEYWORDS')} />
        <meta name="robots" content="INDEX,FOLLOW" />
        <title>{t('DEFAULT_HEAD_TITLE')}</title>
      </Helmet>
      <Router />
    </>
  );
}

export default App;
