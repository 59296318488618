import React from 'react';
import ProductsCarousel, {
  ProductsCarouselProps,
} from 'components/Common/Carousel/ProductsCarousel/ProductsCarousel';

const Carousel = (props: CarouselProps): JSX.Element => {
  return (
    <ProductsCarousel
      productPerPage={props.desktopProductPerPage}
      splideOptions={{
        gap: '20px',
        breakpoints: {
          1199: {
            gap: '10px',
          },
          767: {
            perPage: props.mobileProductPerPage,
          },
        },
      }}
      {...props}
    />
  );
};

export default Carousel;
export type CarouselProps = Omit<
  ProductsCarouselProps,
  'splideOptions' | 'productPerPage'
> & {
  desktopProductPerPage: number;
  mobileProductPerPage?: number;
};
