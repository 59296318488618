import { useContext } from 'react';

import { historyContext } from 'contexts/historyContext';
import { HistoryStateType } from 'types/History';

type Return = {
  isLoaded: boolean;
  prevState?: HistoryStateType;
};

export default function useHistory(): Return {
  const { history, isLoaded } = useContext(historyContext);

  return { isLoaded, prevState: history[history.length - 2] };
}
