import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

export function ScrollToTop(): null {
  const location = useLocation();
  const root = document.querySelector(':root');

  useLayoutEffect(() => {
    root?.setAttribute('style', 'scroll-behavior: auto;');
    document.documentElement.scrollTo(0, 0);
    root?.removeAttribute('style');
  }, [location.pathname]);

  return null;
}
