import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import classNames from 'classnames';

import CustomLink from 'components/Common/CustomLink/CustomLink';
import { getUri } from 'services/generic';
import { pushGtmEvent } from 'services/gtm/gtmEvents';
import { isHomepage } from 'services/validation';

import LogoImg from 'assets/img/header/logo.svg';
import styles from './Logo.module.scss';

export default function Logo({ classes }: LogoType): JSX.Element {
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const isHome = isHomepage(pathname || '');

  function handleClick() {
    pushGtmEvent({
      event: '_header_click',
      category: 'header',
      element: 'logo',
    });
  }

  return (
    <CustomLink
      to={getUri('/')}
      className={classNames(
        styles.logo,
        isHome ? 'disabled-link' : '',
        classes?.container
      )}
      aria-label={t('LABEL_HOME')}
      onClick={handleClick}
      reloadDocument={true}
    >
      {isHome && <h1>{t('LABEL_AFIBEL_LOGO')}</h1>}
      <img src={LogoImg} alt="afibel-logo" height="65" width="128" />
    </CustomLink>
  );
}

type LogoType = {
  classes?: LogoClassesType;
};

type LogoClassesType = {
  container: string;
};
