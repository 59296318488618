import React, {
  ForwardedRef,
  forwardRef,
  MutableRefObject,
  useEffect,
  useRef,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';

import { LARGE_LAPTOP_AND_DESKTOP } from 'settings/mediaQuery';

import styles from '../SearchBar.module.scss';

type PropsType = React.InputHTMLAttributes<HTMLInputElement>;
type RefType = HTMLInputElement;

const SearchBarInputField = (
  props: PropsType,
  inputRef: ForwardedRef<RefType>
): JSX.Element => {
  const { t } = useTranslation();
  const currentIndex = useRef(0);
  const defaultRef = useRef<RefType>(null);
  const ref = (inputRef as MutableRefObject<RefType>) || defaultRef;
  const isDesktop = useMediaQuery({
    query: LARGE_LAPTOP_AND_DESKTOP,
  });

  const possiblePlaceholders = [
    t('LABEL_FIND_A_PRODUCT'),
    t('LABEL_FIND_A_COLOR'),
    t('LABEL_FIND_A_REFERENCE'),
  ];

  useEffect(() => {
    let intervalId: undefined | number;

    if (isDesktop) {
      intervalId = window.setInterval(() => {
        if (ref?.current) {
          const placeholdersLength = possiblePlaceholders.length;
          const currentIndexVal = currentIndex.current;

          currentIndex.current = (currentIndexVal + 1) % placeholdersLength;
          ref.current.placeholder = possiblePlaceholders[currentIndex.current];
        }
      }, 2000);
    } else if (ref?.current) {
      ref.current.placeholder = t('LABEL_SEARCH');
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [ref, currentIndex, isDesktop]);

  return (
    <input
      ref={ref}
      className={styles.searchBar}
      type="text"
      maxLength={128}
      placeholder={
        !isDesktop
          ? t('LABEL_SEARCH')
          : possiblePlaceholders[currentIndex.current]
      }
      {...props}
    />
  );
};

export default forwardRef(SearchBarInputField);
