import React from 'react';
import ReactDOM from 'react-dom';
import TagManager from 'react-gtm-module';

import './assets/styles/main.scss';
import './i18n';
import App from './components/App/App';

const commonTagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_ID,
  ...(process.env.env !== 'production' && {
    auth: process.env.REACT_APP_GTM_AUTH,
    preview: process.env.REACT_APP_GTM_PREVIEW,
  }),
};

const mediaTagManagerArgs = {
  gtmId: process.env.REACT_APP_GTM_MEDIA_ID,
  ...(process.env.env !== 'production' && {
    auth: process.env.REACT_APP_GTM_MEDIA_AUTH,
    preview: process.env.REACT_APP_GTM_MEDIA_PREVIEW,
  }),
};

TagManager.initialize(commonTagManagerArgs);
TagManager.initialize(mediaTagManagerArgs);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
