import React, { useState } from 'react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import MenuItems from './MenuItems';
import { pushGtmEvent } from 'services/gtm/gtmEvents';
import { navigationFormatId } from 'services/string';
import { HierarchyType, MenuItemType } from 'types/menu/Menu';

import styles from './MenuMobile.module.scss';

type Props = {
  items: HierarchyType;
  onClick: () => void;
};

function MenuMobile({ items, onClick }: Props): JSX.Element {
  const [historyMenuNavigation, setHistoryMenuNavigation] = useState<
    MenuItemType[][]
  >([items]);

  function goTo(item: MenuItemType) {
    setHistoryMenuNavigation(prev => [...prev, [item]]);
    onClick();
  }

  function goBack() {
    setHistoryMenuNavigation(prev => [...prev].slice(0, -1));
    onClick();
  }

  function getPrefixId() {
    return historyMenuNavigation
      .slice(1)
      .reduce((accumulator, currentValue) => {
        return accumulator + '_' + navigationFormatId(currentValue[0].label);
      }, 'oct_megamenu');
  }

  function handleItemClick(currentLabel: string) {
    const menuLevels = historyMenuNavigation
      .slice(1)
      .map(item => item[0].label.toLowerCase())
      .concat(currentLabel.toLowerCase())
      .reduce((acc, label, index) => {
        acc[`menu_level_${index + 1}`] = label;

        return acc;
      }, {});

    pushGtmEvent({
      event: '_menu_click',
      ...menuLevels,
    });
  }

  const currentItem =
    historyMenuNavigation.length > 1
      ? historyMenuNavigation[historyMenuNavigation.length - 1][0]
      : undefined;

  const currentItems =
    currentItem?.children ??
    historyMenuNavigation[historyMenuNavigation.length - 1];

  return (
    <div>
      {currentItem && (
        <button className={styles.backButton} type="button" onClick={goBack}>
          <ArrowBackIosIcon className={styles.backArrow} />
          <span>{currentItem.label}</span>
        </button>
      )}
      <MenuItems
        goTo={goTo}
        items={currentItems}
        itemPrefixId={getPrefixId()}
        onClickItem={handleItemClick}
        classes={{
          item: {
            link: historyMenuNavigation.length > 1 ? styles.subItem : undefined,
          },
        }}
      />
    </div>
  );
}

export default MenuMobile;
