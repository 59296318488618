import React, { useCallback, useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from 'react-responsive';
import classNames from 'classnames';

import HeaderBanner from './HeaderBanner';
import RightNavigationLinks from './RightNavigationMenu/RightNavigationMenu';
import SearchButton from './SearchButton/SearchButton';
import Logo from 'components/Common/Logo/Logo';
import SearchBar, {
  OnCloseType,
  OnCloseTypeKeys,
  SearchBarRefType,
} from 'components/SearchBar/SearchBar';
import { cartContext } from 'contexts/cartContext';
import { LARGE_LAPTOP_AND_DESKTOP } from 'settings/mediaQuery';

import MenuBurger from 'assets/img/headerV2/burger.svg';
import PictoClose from 'assets/img/headerV2/close.svg';

import styles from './Header.module.scss';

type Props = {
  /** If megaMenu is open */
  displayMenu: boolean;
  /** Function to update current displayMenu value */
  setDisplayMenu: (displayMenu: boolean) => void;
  isSticky: boolean;
};

function Header({ displayMenu, setDisplayMenu, isSticky }: Props): JSX.Element {
  const { t } = useTranslation();
  const { cart } = useContext(cartContext);
  const searchBarRef = useRef<SearchBarRefType>(null);
  const isLargeLaptop = useMediaQuery({ query: LARGE_LAPTOP_AND_DESKTOP });
  const nbProducts = cart?.nbProducts || 0;

  const closeSearchBar = useCallback((type?: OnCloseTypeKeys) => {
    searchBarRef.current?.clearSearchInput();

    if (
      type === OnCloseType.REDIRECT_CATEGORY ||
      type === OnCloseType.REDIRECT_PRODUCT ||
      type === OnCloseType.TRIGGER_SEARCH
    ) {
      searchBarRef.current?.closeMerch();
    }
  }, []);

  const openMenuClickHandler = useCallback(() => {
    setDisplayMenu(true);
    searchBarRef.current?.closeMerch();
  }, []);

  const showSearchBarBtnHandler = useCallback(() => {
    setDisplayMenu(false);
    searchBarRef.current?.focusSearchInput();
  }, []);

  return (
    <div className={styles.headerContainer} id="header-container">
      <HeaderBanner />
      <div
        id="header-block"
        className={classNames(styles.header, {
          [styles.fullCart]: nbProducts > 0,
          [styles.dnone]: isSticky,
        })}
      >
        <div className={styles.leftContainer}>
          <div className={styles.leftActionBtns}>
            {!displayMenu ? (
              <button
                className={styles.openMenuBtn}
                onClick={openMenuClickHandler}
                type="button"
              >
                <img src={MenuBurger} alt="icon-menu" height="40" width="40" />
              </button>
            ) : (
              <SearchButton onClick={showSearchBarBtnHandler} />
            )}
          </div>
        </div>
        <Logo />
        <div className={styles.rightContainer}>
          {displayMenu && !isLargeLaptop ? (
            <button
              className={styles.closeMenuBtn}
              type="button"
              onClick={() => setDisplayMenu(false)}
              aria-label={t('LABEL_CLOSE')}
            >
              <img src={PictoClose} alt="icon-close-menu" />
            </button>
          ) : (
            <div className={styles.rightNavigationContainer}>
              <RightNavigationLinks />
            </div>
          )}
        </div>
      </div>
      {!isLargeLaptop && (
        <div className={classNames(styles.searchBarContainer)}>
          <SearchBar
            ref={searchBarRef}
            sticky={isSticky}
            onClose={closeSearchBar}
            onClickAway={closeSearchBar}
            onRedirect={() => closeSearchBar(OnCloseType.TRIGGER_SEARCH)}
          />
        </div>
      )}
    </div>
  );
}

export default Header;
