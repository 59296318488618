import useSWRImmutable from 'swr/immutable';

import { FetchError } from 'exceptions/FetchError';
import { htmlFetcher } from 'services/fetcher';

export function useMediaServer(
  path: string | null,
  additionalRequestParams: RequestInit = {}
): {
  content?: string;
  error?: FetchError;
} {
  const { data: content, error } = useSWRImmutable<string, FetchError>(
    path ? [path, additionalRequestParams] : null,
    htmlFetcher
  );

  return {
    content,
    error,
  };
}
