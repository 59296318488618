import TagManager from 'react-gtm-module';

import useEnv from 'hooks/useEnv';
import { getProductColorModel, getProductModel } from 'services/gtm/gtmUtils';
import { Cart } from 'types/Cart';
import {
  GtmEventCartProductType,
  GtmEventDataType,
} from 'types/GtmV2/GtmPageView';

const { gtmId } = useEnv();

export function pushGtmEvent(data: GtmEventDataType = null, cart?: Cart): void {
  const dataLayer = cart
    ? Object.assign(data, {
        cart_id: cart.cartId,
        cart_products: formatCartProducts(cart),
      })
    : data;
  TagManager.dataLayer({ gtmId, dataLayer });
}

export function logInvalidFieldsToGtmEvent(data: {
  page_type: string;
  form_type: string;
  errorsKeys: Array<string>;
}): void {
  const eventData = {
    event: '_form_error',
    category: data.page_type,
    element: data.form_type,
    detail: data.errorsKeys.map(error => formsLabels[error] || error).join('|'),
  };

  TagManager.dataLayer({ gtmId, dataLayer: eventData });
}

export function formatCartProducts(cart: Cart): GtmEventCartProductType[] {
  return cart.products.map(product => {
    const { sku } = product;
    return {
      r: getProductModel(sku),
      rc: getProductColorModel(sku),
      rct: sku,
    };
  });
}

// utlisés uniquement pour remonter les labels des champs invalides sur tous les formulaires du site
const formsLabels = {
  civility: 'civilité',
  firstName: 'prénom',
  surname: 'nom de famille',
  lastName: 'nom de famille',
  newEmail: 'ma nouvelle adresse e-mail',
  nameWay: 'n° et nom de voie',
  street: 'n° et nom de voie',
  postalCode: 'code postal',
  postCode: 'code postal',
  city: 'ville',
  country: 'pays',
  email: 'adresse e-mail',
  confirmEmail: 'confirmation adresse e-mail',
  password: 'mot de passe',
  newPassword: 'nouveau mot de passe',
  confirmPassword: 'confirmation mot de passe',
  confirmNewPassword: 'confirmez le nouveau mot de passe',
  subject: 'objet',
  nClient: 'n° client',
  paymentMethod: 'mon mode de paiement',
  cgv: 'j’ai lu et accepte les conditions générales de vente',
  newsAfibelMail:
    'je souhaite recevoir les nouveautés et les offres exclusives d’Afibel, par mail',
  newsAfibelSms:
    'je souhaite recevoir les nouveautés et les offres exclusives d’Afibel, par SMS',
  newsPartnerAfibelMail:
    'je souhaite recevoir les offres commerciales des partenaires d’Afibel, par mail',
};
