import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import SearchBarCategories from './SearchBarCategories';
import SearchBarProducts from './SearchBarProducts';
import { Acp } from 'types/Acp';

import styles from '../SearchBar.module.scss';

export const CloseTypes = {
  REDIRECT_PRODUCT: 'productRedirection',
  REDIRECT_CATEGORY: 'categoryRedirection',
  TRIGGER_SEARCH: 'triggerSearch',
} as const;

export type CloseTypesKeys = typeof CloseTypes[keyof typeof CloseTypes];

type Props = {
  data?: Acp;
  onClose?: (type?: CloseTypesKeys) => void;
  onClickSeeAll: (e: React.MouseEvent) => void;
};

export default function AutoCompletion({
  data,
  onClose,
  onClickSeeAll,
}: Props): JSX.Element {
  const { t } = useTranslation();

  useEffect(() => {
    document.body.classList.add(styles.bodyAutoCompletionOpen);

    return () => document.body.classList.remove(styles.bodyAutoCompletionOpen);
  }, []);

  return createPortal(
    <div className={styles.autocomplete}>
      <SearchBarCategories
        category={data?.page}
        closeAcp={() => onClose && onClose(CloseTypes.REDIRECT_CATEGORY)}
      />
      <div className={styles.products}>
        <div className={styles.productsHeader}>
          <span>{t('LABEL_PRODUCTS')}</span>
        </div>
        <div className={styles.productsResult}>
          <SearchBarProducts
            products={data?.product}
            closeAcp={() => onClose && onClose(CloseTypes.REDIRECT_PRODUCT)}
            productPerPage={3}
          />
        </div>
        <div className={styles.seeAll}>
          <button onClick={onClickSeeAll}>
            <span>
              {t('LABEL_SEE_ALL_RESULTS')} <ArrowForwardIosIcon />
            </span>
          </button>
        </div>
      </div>
    </div>,
    document.getElementById('headerContainerLayout') || document.body
  );
}
