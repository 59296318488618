import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { cartContext } from 'contexts/cartContext';
import CustomLink from 'components/Common/CustomLink/CustomLink';
import { getUri } from 'services/generic';
import { pushGtmEvent } from 'services/gtm/gtmEvents';

import CartPicto from 'assets/img/headerV2/cart.svg';

import styles from './CartLink.module.scss';

type CartLinkProps = {
  classes?: {
    navLink?: string;
    image?: string;
    nbProduct?: string;
  };
};

function CartLink({ classes }: CartLinkProps): JSX.Element {
  const { cart } = useContext(cartContext);
  const { t } = useTranslation();
  const nbProducts = cart?.nbProducts || 0;
  const handleClick = () => {
    pushGtmEvent({
      event: '_header_click',
      category: 'header',
      element: 'mon panier',
    });
  };

  return (
    <CustomLink
      className={classNames(classes?.navLink, styles.container)}
      to={getUri('/checkout/cart')}
      title={t('LABEL_BASKET')}
      onClick={handleClick}
    >
      <img
        src={CartPicto}
        alt="my-basket"
        className={classes?.image}
        height={28}
        width={22}
      />
      <span>{t('LABEL_BASKET')}</span>
      {nbProducts > 0 && (
        <div className={classNames(styles.nbProducts, classes?.nbProduct)}>
          {nbProducts}
        </div>
      )}
    </CustomLink>
  );
}

export default CartLink;
