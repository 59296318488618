import {
  GroupedMenuItemType,
  MenuItemType,
  NoLayersType,
} from 'types/menu/Menu';

export function groupItemsByColumns(
  items: MenuItemType[],
  needSkipColumn?: (column: number) => boolean
): GroupedMenuItemType {
  return items.reduce(function (r, a) {
    const menuColumn = a.metadata.menuColumn;
    if (needSkipColumn && needSkipColumn(menuColumn)) {
      return r;
    }

    r[menuColumn] = r[menuColumn] || [];
    r[menuColumn].push(a);

    return r;
  }, Object.create(null));
}

/**
 * Check if the list contains one item visible
 */
export function hasAtLeastOneItemsVisible(
  items: MenuItemType[],
  metadataName: keyof NoLayersType
): boolean {
  return items.some(item => !item.metadata[metadataName]);
}
