import { isUk } from 'services/currentCountry';

export const GTM_PAGE_VIEW = '_page_view';

export const GTM_PAGE_HOME = "page d'accueil";
export const GTM_PAGE_SEARCH = 'recherche';
export const GTM_PAGE_PRODUCT = 'fiche produit';
export const GTM_PAGE_LIST = 'page liste';
export const GTM_PAGE_ERROR = 'erreur';
export const GTM_PAGE_ACCOUNT = 'compte';
export const GTM_PAGE_DIRECT_ORDER = 'commande direct';
export const GTM_PAGE_CART = 'panier';
export const GTM_PAGE_LOGIN = 'identification';
export const GTM_PAGE_SHIPPING = 'livraison';
export const GTM_PAGE_PAYMENT = 'paiement';
export const GTM_PAGE_CONFIRMATION = 'confirmation de commande';
export const GTM_PAGE_OTHER = 'autre';

export const GTM_CATEGORY_PAGE = 'pge';
export const GTM_CATEGORY_USER = 'usr';
export const GTM_CATEGORY_PRODUCTS = 'pdt';
export const GTM_CATEGORY_ORDER = 'odr';
export const GTM_CATEGORY_SEARCH = 'srh';

export const GTM_ENV_CURRENCY = isUk() ? 'pounds' : 'eur';
export const GTM_ENV_VERSION = 'responsive';

export const GTM_SEARCH_WITH_RESULT = 'avec résultats';
export const GTM_SEARCH_WITHOUT_RESULT = 'sans résultats';
export const GTM_SEARCH_CATEGORY_REDIRECTION = 'redirection des catégories';
export const GTM_SEARCH_PRODUCT_REDIRECTION = 'redirection des produits';

export const GTM_CART_PRODUCTS_PAGE = [
  GTM_PAGE_CART,
  GTM_PAGE_LOGIN,
  GTM_PAGE_SHIPPING,
  GTM_PAGE_PAYMENT,
  GTM_PAGE_CONFIRMATION,
  GTM_PAGE_OTHER,
];
