import {
  BaseComponent,
  Components,
  EventInterface,
  Splide,
} from '@splidejs/splide';

export default function FooterBannerCarouselExtension(
  Splide: Splide,
  { Autoplay }: Components
): BaseComponent {
  const { on } = EventInterface(Splide);

  const mount = () => {
    on('drag', disableAutoPlay);
  };

  const disableAutoPlay = () => {
    Autoplay.pause();
  };

  return {
    mount,
  };
}
