import React, { memo } from 'react';
import { useMediaQuery } from 'react-responsive';

import { MOBILE_AND_TABLET } from 'settings/mediaQuery';

import NavigationMenuDesktop from './NavigationMenuDesktop';
import MobileNavigationDrawer from './MobileNavigationDrawer';

/**
 * Megamenu - Nav bar with global category and its content <br />
 * Contains NavigationMenuDesktop and NavigationMenuMobile
 */
function NavigationMenu({
  show,
  visibleSticky,
}: {
  /** Show content */
  show: boolean;
  visibleSticky: boolean;
}): JSX.Element | null {
  const isMobileAndTablet = useMediaQuery({
    query: MOBILE_AND_TABLET,
  });

  return (
    <>
      {isMobileAndTablet && <MobileNavigationDrawer isDrawerOpen={show} />}
      {!isMobileAndTablet && <NavigationMenuDesktop sticky={visibleSticky} />}
    </>
  );
}

export default memo(NavigationMenu);
