import { ENV_APP_MODE, ENV_ISICOM_ENABLE } from 'settings/env';

export const BASE_URL = 'https://webchat.afibel.com';
export const ISICOM_JS_PATH = '//webchat.afibel.com/webchat/js/isicom.js';
export const ISICOM_CSS_PATH = '//webchat.afibel.com/webchat/css/isicom.css';

export function isEnable(): boolean {
  return '1' === ENV_ISICOM_ENABLE;
}

export function getIdIntegration(): number {
  return 'prod' === ENV_APP_MODE ? 2 : 1;
}
