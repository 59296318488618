import { useEffect, useRef, useState } from 'react';
import useSWR from 'swr';

import { apiUrls } from 'services/api-urls';
import { fetcher } from 'services/fetcher';
import { ENV_API_KEY_SYMFONY } from 'settings/env';

/**
 * Never call directly this hook, get functions from CartProvider to prevent useEffect execution
 */
export default function usePrivilege(): UsePrivilegeType {
  const LOCAL_CODE_KEY = 'privilegeCode';
  const localCode = localStorage.getItem(LOCAL_CODE_KEY);
  const [privilegeCode, setPrivilegeCode] = useState<string>('');
  const abortControllerRef = useRef<AbortController | null>(null);
  const { data: defaultCode } = useSWR<string | undefined>(
    apiUrls.getDefaultPrivilege(),
    fetcher,
    {
      revalidateOnFocus: false,
      refreshInterval: 0,
    }
  );

  // At the first time load website, we check the privilege code in local
  useEffect(() => {
    if (undefined !== defaultCode) {
      if (localCode && defaultCode !== localCode) {
        const abortController = new AbortController();
        abortControllerRef.current = abortController;

        validatePrivilegeCode(localCode, {
          signal: abortController.signal,
        }).then(({ valid_code: isValid }) => {
          if (!abortController.signal.aborted) {
            updatePrivilegeCode(!isValid ? defaultCode : localCode);
          }
        });
      } else {
        updatePrivilegeCode(defaultCode);
      }
    }
  }, [defaultCode]);

  // This effect is called because when `privilegeCode` is updated, the localCode can have a new value
  useEffect(() => {
    if (!localCode && defaultCode) {
      updatePrivilegeCode(defaultCode);
    }
  }, [localCode, defaultCode]);

  function updatePrivilegeCode(code: string): void {
    abortControllerRef.current?.abort(); // Prevent override the privilege code by default validation

    localStorage.setItem(LOCAL_CODE_KEY, code);
    setPrivilegeCode(code);
  }

  function resetPrivilegeCode(): void {
    abortControllerRef.current?.abort(); // Prevent override the privilege code by default validation

    localStorage.removeItem(LOCAL_CODE_KEY);
    setPrivilegeCode('');
  }

  async function validatePrivilegeCode(
    code: string,
    additionalOptions?: RequestInit
  ): Promise<PrivilegeValidateResponse> {
    const defaultResponse: PrivilegeValidateResponse = {
      valid_code: false,
      message: [],
    };

    const requestOptions: RequestInit = {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
        'X-AUTH-TOKEN': ENV_API_KEY_SYMFONY,
      },
      body: JSON.stringify({
        privilegeCode: code.toUpperCase(),
      }),
    };

    try {
      const response = await fetch(apiUrls.getPrivilegeValidate(), {
        ...requestOptions,
        ...additionalOptions,
      });
      if (!response.ok) return defaultResponse;
      return await response.json();
    } catch (error) {
      return defaultResponse;
    }
  }

  return {
    privilegeCode,
    setPrivilegeCode: updatePrivilegeCode,
    updatePrivilegeCode,
    validatePrivilegeCode,
    resetPrivilegeCode,
  };
}

type UsePrivilegeType = {
  privilegeCode: string;
  setPrivilegeCode: (privilegeCode: string) => void;
  updatePrivilegeCode: (code: string) => void;
  validatePrivilegeCode: (code: string) => Promise<PrivilegeValidateResponse>;
  resetPrivilegeCode: () => void;
};

export type PrivilegeValidateResponse = {
  valid_code: boolean;
  message: string[];
};
