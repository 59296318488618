import { getProductAccess } from './gtmUtils';
import { CartBodyRequest } from 'providers/CartProvider';
import { GtmProductType } from 'types/gtm/GtmPageView';

const LOCAL_ITEM_KEY = 'add_to_cart';

export function getAddToCart(): ItemType[] {
  const storedItem = localStorage.getItem(LOCAL_ITEM_KEY);
  return storedItem ? JSON.parse(storedItem) : [];
}

export function getAddToCartBySku(
  sku: string,
  localAddToCart?: ItemType[]
): ItemType | undefined {
  return (localAddToCart ?? getAddToCart()).find(p => p.sku === sku);
}

export function updateLocalAddToCart(
  cartProducts: CartBodyRequest | CartBodyRequest[],
  type: string,
  previousUrl?: string
): void {
  const item = getAddToCart();
  const products = Array.isArray(cartProducts) ? cartProducts : [cartProducts];

  products.forEach(product => {
    const data = {
      sku: product.sku,
      type: type,
      access: getProductAccess(previousUrl),
    };
    const existingSkuIndex = item.findIndex(p => p.sku === product.sku);

    if (existingSkuIndex >= 0) item[existingSkuIndex] = data;
    else item.push(data);
  });

  localStorage.setItem(LOCAL_ITEM_KEY, JSON.stringify(item));
}

export function deleteLocalAddToCart(sku: string): void {
  const item = getAddToCart();
  const filteredSkus = item.filter(p => p.sku !== sku);

  localStorage.setItem(LOCAL_ITEM_KEY, JSON.stringify(filteredSkus));
}

export function retrieveOrderAddToCartType(
  gtmProducts: GtmProductType[]
): string {
  const types = gtmProducts.map(gp => gp.add_to_cart_type);
  const filteredTypes = types.filter(element => element !== undefined);
  const uniqTypes = Array.from(new Set(types)) as string[];
  const mapping = {
    'fiche produit': 'standard uniquement',
    'commande direct': 'commande direct uniquement',
  };

  if (filteredTypes.length > 1) {
    return 'mixte';
  } else {
    return mapping[uniqTypes[0]];
  }
}

type ItemType = Record<'sku' | 'type' | 'access', string>;
